import * as routeImpl from './impl';
import config from 'config';
import { is_empty } from '@developwithpassion/matchers_js';
import { navigate } from 'gatsby';
export { navigate, withPrefix } from 'gatsby';

const stripPathPrefix = (pathToPotentiallyFix = '', prefix = config.pathPrefix) =>
  is_empty(prefix)
    ? pathToPotentiallyFix
    : pathToPotentiallyFix.replace(`${prefix}/`, '');

export const route = async ({ path = '/', query = {} } = {}) =>
  await navigate(path, query);

const homeUri = () => '/';

const browseStoresUri = () => '/browse-stores';

const busyUri = () => '/busy';

const accountSetupUri = () => '/account-setup';

const loginUri = () => '/login';

const signupUri = () => '/signup';

const landingUri = () => '/landing';

const notFoundUri = () => '/404';

const home = async () => await route({ path: homeUri() });

const browseStores = async () => await route({ path: browseStoresUri() });

const busy = async () => await route({ path: busyUri() });

const accountSetup = async () => await route({ path: accountSetupUri() });

const login = async () => await route({ path: loginUri() });

const landing = async () => await route({ path: landingUri() });

const notFound = async () => await route({ path: notFoundUri() });

export const routes = {
  ...routeImpl.buildRoutes(route),
  busy,
  browseStores,
  home,
  accountSetup,
  login,
  landing,
  notFound,
};

export const uris = {
  ...routeImpl.uris,
  homeUri,
  browseStoresUri,
  busyUri,
  accountSetupUri,
  loginUri,
  landingUri,
  notFoundUri,
  signupUri,
};

export const currentPath = () => stripPathPrefix(window.location.pathname);

export const __test__ = {
  stripPathPrefix,
};
