import { route } from 'router';

export const createTasks = ({ ready, views }) => {
  const init = async () => {
    const { initialPath } = views();

    await route({ path: initialPath });
    ready();
  };

  return {
    init,
  };
};
