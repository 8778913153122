import { noOp } from 'utils/core/funcy';
import { useEffect } from 'react';

/* for embedded menus iframe resizing */

const postSizeChange = size => {
  if (window && window.parent && window.parent.postMessage) {
    window.parent.postMessage(size, '*');
  }
};

const createResizeObserver = handler =>
  typeof window !== 'undefined' && window.ResizeObserver
    ? new ResizeObserver(handler)
    : { observe: noOp };

const resizeObserver = createResizeObserver(entries => {
  postSizeChange(entries[0].target.clientHeight);
});

const withResizePublisher = () => {
  useEffect(() => {
    postSizeChange(document.body.scrollHeight);

    resizeObserver.observe(document.body);
  }, []);
};

export default withResizePublisher;
