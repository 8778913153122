import { routes } from 'router';

const { customerInfo } = routes;

export default ({ clientId, menuId }) => {
  const actionTitle = 'CHECKOUT';

  const startCheckout = async () => {
    await customerInfo({ clientId, menuId });
  };

  return {
    isCart: true,
    onAction: startCheckout,
    actionTitle,
    startCheckout,
  };
};
