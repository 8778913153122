import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { equal_to, is_false } from '@developwithpassion/matchers_js';
import { identity, noOp, pipe, prop } from 'utils/core/funcy';
import _ from 'lodash';
import { centsToDollars } from 'utils/ui/mappers';
import config from 'config';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import { accessors as storeAccessors } from '../../viewModel';

const { retailPreOrderId = noOp } = storeAccessors;

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const store = viewModelAttribute('store', ({ store }) => store, identity);

const orderSummaries = viewModelAttribute(
  'orderSummaries',
  store,
  prop('orderSummaries')
);

const removeItemFromPreOrderStatus = viewModelAttribute(
  'removeItemFromPreOrderStatus',
  ({ store: { removeItemFromPreOrderStatus } }) => removeItemFromPreOrderStatus,
  createRequestStatusViews
);

const updateItemQuantityForPreOrderStatus = viewModelAttribute(
  'updateItemQuantityForPreOrderStatus',
  ({ store: { updateItemQuantityForPreOrderStatus } }) =>
    updateItemQuantityForPreOrderStatus,
  createRequestStatusViews
);

const nulloOrderSummary = {
  items: [],
  total: 0,
};

const currentPreOrder = viewModelAttribute(
  'currentPreOrder',
  orderSummaries,
  retailPreOrderId,
  (summaries, preOrderId) => summaries[preOrderId] || nulloOrderSummary
);

const nullMenuImageArray = [{ url: config.images.fallback }];

const mapCartItemImageUrl = ([cartItem]) => (!cartItem ? false : cartItem.url);

const cartLineItemMapper = ({
  objectId: id,
  product: { objectId: productId, menuImage, name, weight },
  price,
  ...rest
}) => ({
  id,
  productId,
  price: centsToDollars(price),
  ...rest,
  imageUrl: mapCartItemImageUrl(menuImage || nullMenuImageArray),
  name,
  weight,
});

const rawCartLineItems = viewModelAttribute(
  'rawCartLineItems',
  currentPreOrder,
  ({ items = [] } = {}) => items
);

const cartLineItems = viewModelAttribute('cartLineItems', rawCartLineItems, items =>
  items.map(cartLineItemMapper)
);

const groupedCartItems = viewModelAttribute('groupedCartItems', cartLineItems, items => {
  const groupedItems = _(items)
    .groupBy(item => item.productId)
    .map((groupedItems, productId) => {
      // eslint-disable-next-line prefer-destructuring
      const {
        price,
        unitWeightUOM,
        unitWeight,
        imageUrl,
        name,
        strain,
      } = groupedItems[0];

      return {
        productId,
        orderItemIds: _.map(groupedItems, 'id'),
        price,
        unitWeightUOM,
        unitWeight,
        quantityInCart: groupedItems.length,
        imageUrl,
        name,
        strain,
      };
    })
    .value();
  return groupedItems;
});

const totalItems = viewModelAttribute('totalItems', cartLineItems, items => items.length);

const isEmpty = viewModelAttribute('isEmpty', totalItems, equal_to(0));

const canPlaceOrder = viewModelAttribute('canPlaceOrder', isEmpty, is_false);

const cartTotal = viewModelAttribute(
  'cartTotal',
  currentPreOrder,
  pipe(prop('total'), val => val / 100)
);

export const accessors = {
  ...storeAccessors,
  cartTotal,
  cartLineItems,
  currentPreOrder,
  canPlaceOrder,
  isEmpty,
  totalItems,
  removeItemFromPreOrderStatus,
  updateItemQuantityForPreOrderStatus,
  groupedCartItems,
};

export const createViewModel = createViewModelMap(accessors);
