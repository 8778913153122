export { default as CartSummary } from './embeddedOrderSummary';
import './embeddedOrder';
import { page, withBreakpointDetection, withSEO } from 'utils/views';
import Template from './template';
import { compose } from 'utils/core/funcy';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';

const FullTemplate = compose(withBreakpointDetection())(Template);

export const RetailCart = withSEO({
  title: 'Cart',
})(
  page({
    Template: FullTemplate,
    createTasks,
    createViewModel,
  })
);
