import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { fullScreenSpinnerContainer } from './styles';

const FullScreenSpinner = ({ message = null } = {}) => (
  <div className={fullScreenSpinnerContainer}>
    <LoadingOutlined className="spinner" style={{ fontSize: 35 }} />
    {message && <span className="message">{message}</span>}
  </div>
);

export default FullScreenSpinner;
