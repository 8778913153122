import {
  addProductToCartKey,
  removeItemFromPreOrderKey,
  updateItemQuantityForPreOrderKey,
} from '../events/impl/embedded';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';
import { eventNames } from '../events';
import { reduceWith } from 'utils/reducers';
import { sliceReducer } from '../sliceReducer';

export const createExtraReducerState = () => ({
  menuId: '',
  clientId: '',
  retailPreOrderId: -1,
  supportsPreOrders: false,
  supportsDelivery: false,
  storeType: 'Recreational',
  storeName: '',
  storeLocation: {},
  orderSummaries: {},
  addProductToCartStatus: requestState(),
  removeItemFromPreOrderStatus: requestState(),
  updateItemQuantityForPreOrderStatus: requestState(),
  productModalVisible: false,
  lastVariantAdded: '',
});

export const orderSummaryUpdated = sliceReducer(
  (slice, { payload: { retailPreOrderId, details } }) => {
    slice.orderSummaries[retailPreOrderId] = details;
  }
);

reduceWith(orderSummaryUpdated, eventNames.orderSummaryUpdated);

export const orderCompleted = sliceReducer((slice, { payload: { retailPreOrderId } }) => {
  delete slice.orderSummaries[retailPreOrderId];
  slice.retailPreOrderId = -1;
});

reduceWith(orderCompleted, eventNames.orderCompleted);

registerRequestStatusChangeReducers(
  addProductToCartKey,
  val => val.store.addProductToCartStatus
);

registerRequestStatusChangeReducers(
  removeItemFromPreOrderKey,
  val => val.store.removeItemFromPreOrderStatus
);

registerRequestStatusChangeReducers(
  updateItemQuantityForPreOrderKey,
  val => val.store.updateItemQuantityForPreOrderStatus
);
