import { or_criteria as any, criteria as where } from '@developwithpassion/match_js';
import {
  anything,
  between,
  condition,
  is_null_or_undefined,
  never_matches,
  not,
} from '@developwithpassion/matchers_js';
import { comparisons, sort } from '@developwithpassion/comparers_js';
import {
  createRange,
  parentCategories,
  parentCategoriesMap,
  productsCBDRange,
  productsList,
  productsPriceRange,
  productsTHCRange,
} from '../../../shared/viewModel';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { flat_map, flatten } from '@developwithpassion/arrays_js';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';
import { normalizeFilterItems } from './options';
import { reducerForUniqueMap } from '../../../utils';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const missingValue = 'N/A';

const filterIsActive = selectedFilters =>
  where({
    id: condition(val => !!selectedFilters[val]),
  });

export const sortByFilterName = sort.by_fixed('name', [missingValue]).reverse;

const isParentCategory = where({
  productCategoryParentId: not(is_null_or_undefined),
});

const createInitialRange = ({ name, parentRange, sliderRange, expansionGroupId }) =>
  viewModelAttribute(name, parentRange, sliderRange, (parent, { changed, ...rest }) => ({
    ...createRange(changed ? rest : parent),
    groupId: expansionGroupId,
    changed,
  }));

const rawTHCRange = viewModelAttribute(
  'rawTHCRange',
  ({ storeFilters: { thcRange } }) => thcRange,
  identity
);

const thcRange = createInitialRange({
  name: 'thcRange',
  parentRange: productsTHCRange,
  sliderRange: rawTHCRange,
  expansionGroupId: 'thcRange',
});

const createTHCFilter = ({ changed, start, end }) =>
  changed
    ? condition(({ minTHCMax, maxTHCMax }) =>
        any({
          minTHCMax: between(start, end),
          maxTHCMax: between(start, end),
          start: between(minTHCMax, maxTHCMax),
          end: between(minTHCMax, maxTHCMax),
        })({ start, end, minTHCMax, maxTHCMax })
      )
    : anything;

const thcFilter = viewModelAttribute('thcFilter', thcRange, createTHCFilter);

const rawCBDRange = viewModelAttribute(
  'rawCBDRange',
  ({ storeFilters: { cbdRange } }) => cbdRange,
  identity
);

const cbdRange = createInitialRange({
  name: 'cbdRange',
  parentRange: productsCBDRange,
  sliderRange: rawCBDRange,
  expansionGroupId: 'cbdRange',
});

const createCBDFilter = ({ changed, start, end }) =>
  changed
    ? condition(({ minCBDMax, maxCBDMax }) =>
        any({
          minCBDMax: between(start, end),
          maxCBDMax: between(start, end),
          start: between(minCBDMax, maxCBDMax),
          end: between(minCBDMax, maxCBDMax),
        })({ start, end, minCBDMax, maxCBDMax })
      )
    : anything;

const cbdFilter = viewModelAttribute('cbdFilter', cbdRange, createCBDFilter);

const rawPriceRange = viewModelAttribute(
  'rawPriceRange',
  ({ storeFilters: { priceRange } }) => priceRange,
  identity
);

const priceRange = createInitialRange({
  name: 'priceRange',
  parentRange: productsPriceRange,
  sliderRange: rawPriceRange,
  expansionGroupId: 'priceRange',
});

const priceFilter = viewModelAttribute(
  'priceFilter',
  priceRange,
  ({ changed, start, end }) =>
    changed
      ? where({
          price: between(start, end),
        })
      : anything
);

const expandedFilterGroups = viewModelAttribute(
  'expandedFilterGroups',
  ({ storeFilters: { expandedFilterGroups } }) => expandedFilterGroups,
  identity
);

const isExpanded = viewModelAttribute(
  'isExpanded',
  expandedFilterGroups,
  expandedFilterGroups => groupdId => !!expandedFilterGroups[groupdId]
);

const selectedFilterGroupItems = viewModelAttribute(
  'selectedFilterGroupItems',
  ({ storeFilters: { selectedFilterGroupItems } }) => selectedFilterGroupItems,
  identity
);

const isSelected = viewModelAttribute(
  'isSelected',
  selectedFilterGroupItems,
  selectedFilterGroupItems => id => !!selectedFilterGroupItems[id]
);

const hasActiveFilterGroupItems = viewModelAttribute(
  'hasActiveFilterGroupItems',
  selectedFilterGroupItems,
  val => Object.entries(val).reduce((acc, [, val]) => acc || val, false)
);

const hasActiveRangeFilters = viewModelAttribute(
  'hasActiveRangeFilters',
  thcRange,
  cbdRange,
  priceRange,
  (thcRange, cbdRange, priceRange) =>
    [thcRange, cbdRange, priceRange].reduce((acc, { changed }) => acc || changed, false)
);

export const hasActiveFilters = viewModelAttribute(
  'hasActiveFilters',
  hasActiveFilterGroupItems,
  hasActiveRangeFilters,
  (hasActiveFilterGroupItems, hasActiveRangeFilters) =>
    hasActiveFilterGroupItems || hasActiveRangeFilters
);

const activeFilterGroupItems = viewModelAttribute(
  'activeFilterGroupItems',
  selectedFilterGroupItems,
  val =>
    Object.entries(val)
      .filter(([, val]) => val)
      .map(([key]) => key)
);

export const strains = viewModelAttribute('Strains Filter Values', productsList, val =>
  normalizeFilterItems(
    Object.values(val.reduce(reducerForUniqueMap('strainName'), {})).sort(
      comparisons.default
    )
  )
);

export const strainTypes = viewModelAttribute(
  'Strain Types Filter Values',
  productsList,
  val =>
    normalizeFilterItems(
      Object.values(val.reduce(reducerForUniqueMap('strainTypeName'), {})).sort(
        comparisons.default
      )
    )
);

export const sizes = viewModelAttribute('Sizes Filter Values', productsList, val =>
  normalizeFilterItems(
    Object.values(
      val.reduce(
        reducerForUniqueMap('sizeDisplayName', ({ sizeDisplayName, size }) => ({
          sizeDisplayName,
          size,
        })),
        {}
      )
    )
      .sort(sort.by('size'))
      .map(({ sizeDisplayName }) => sizeDisplayName)
  )
);

export const brands = viewModelAttribute('Brands Filter Values', productsList, val =>
  normalizeFilterItems(
    Object.values(
      val.reduce(
        reducerForUniqueMap('productBrandId', ({ productBrandId, productBrandName }) => ({
          productBrandId,
          productBrandName,
        })),
        {}
      )
    ).sort(sort.by('productBrandName')),
    ({ productBrandName }) => productBrandName
  )
);

const isAParentCategory = viewModelAttribute(
  'isAParentCategory',
  parentCategoriesMap,
  map => id => map.has(id)
);

const childCategoriesMap = viewModelAttribute('childCategoriesMap', productsList, val =>
  val.filter(isParentCategory).reduce(
    reducerForUniqueMap(
      'productCategoryId',
      ({ productCategoryId, productCategoryParentId, productCategoryName }) => ({
        id: productCategoryId,
        key: productCategoryId,
        attributeName: 'productCategoryId',
        parentId: productCategoryParentId,
        title: productCategoryName,
        name: productCategoryName,
        value: productCategoryId,
      })
    ),
    {}
  )
);

export const childCategories = viewModelAttribute(
  'childCategories',
  childCategoriesMap,
  Object.values
);

export const childCategoriesForParent = viewModelAttribute(
  'childCategoriesForParent',
  childCategories,
  val => parentId => val.filter(where({ parentId }))
);

const createGroupFilterItem = ({
  groupName,
  attributeName,
  nameMapper = identity,
  valueMapper = identity,
} = {}) => value => ({
  attributeName,
  id: `${groupName}-${valueMapper(value)}`,
  key: `${groupName}-${valueMapper(value)}`,
  title: nameMapper(value) || missingValue,
  name: nameMapper(value) || missingValue,
  value: valueMapper(value),
});

export const createSimpleFilterGroup = ({
  sourceView,
  id,
  title,
  attribute,
  createChildMapper = createGroupFilterItem,
} = {}) =>
  viewModelAttribute(`${title} - Simple Filter Group`, sourceView, items => ({
    id,
    key: id,
    title,
    children: items
      .map(createChildMapper({ groupName: id, attributeName: attribute }))
      .sort(sortByFilterName),
  }));

export const strainFilterGroup = createSimpleFilterGroup({
  sourceView: strains,
  id: 'strain',
  title: 'Strain',
  attribute: 'strainName',
});

export const strainTypeFilterGroup = createSimpleFilterGroup({
  sourceView: strainTypes,
  id: 'strain-type',
  title: 'Strain Type',
  attribute: 'strainTypeName',
});

export const sizesFilterGroup = createSimpleFilterGroup({
  sourceView: sizes,
  id: 'size',
  title: 'Size',
  attribute: 'sizeDisplayName',
});

export const brandsFilterGroup = createSimpleFilterGroup({
  sourceView: brands,
  id: 'brand',
  title: 'Brand',
  attribute: 'productBrandId',
  createChildMapper: ({ groupName, attributeName }) =>
    createGroupFilterItem({
      groupName,
      attributeName,
      nameMapper: ({ productBrandName }) => productBrandName,
      valueMapper: ({ productBrandId }) => productBrandId,
    }),
});

export const parentCategoryGroupMap = ({ childCategoriesForParent }) => ({
  id,
  ...rest
}) => ({
  id,
  ...rest,
  children: childCategoriesForParent(id),
});

export const parentCategoriesFilterGroup = viewModelAttribute(
  'parentCategoriesFilterGroup',
  parentCategories,
  childCategoriesForParent,
  (parentCategories, childCategoriesForParent) => ({
    id: 'categories',
    key: 'categories',
    title: 'Category',
    children: parentCategories.map(
      parentCategoryGroupMap({
        childCategoriesForParent,
      })
    ),
  })
);

export const filterGroups = viewModelAttribute(
  'filterGroups',
  strainFilterGroup,
  strainTypeFilterGroup,
  brandsFilterGroup,
  parentCategoriesFilterGroup,
  sizesFilterGroup,
  (
    strainFilterGroup,
    strainTypeFilterGroup,
    brandsFilterGroup,
    parentCategoriesFilterGroup,
    sizesFilterGroup
  ) =>
    [
      parentCategoriesFilterGroup,
      strainFilterGroup,
      strainTypeFilterGroup,
      brandsFilterGroup,
      sizesFilterGroup,
    ].filter(item => item.children.length > 0)
);

export const filterGroupsMap = viewModelAttribute(
  'filterGroupsMap',
  filterGroups,
  filterGroups => filterGroups.reduce(reducerForUniqueMap('id', identity), {})
);

const getChildren = ({ children = [], ...rest }) =>
  [{ ...rest }].concat(flat_map(getChildren, children));

const allActiveItemsInGroup = viewModelAttribute(
  'allActiveItemsInGroup',
  selectedFilterGroupItems,
  filterGroupsMap,
  (selectedFilterGroupItems, filterGroupsMap) => key =>
    flatten(getChildren(filterGroupsMap[key]))
      .filter(filterIsActive(selectedFilterGroupItems))
      .map(({ id }) => id)
);

const isActiveFilterGroup = viewModelAttribute(
  'isActiveFilterGroup',
  selectedFilterGroupItems,
  selectedFilterGroupItems => group => {
    const items = flatten(getChildren(group)).filter(
      filterIsActive(selectedFilterGroupItems)
    );

    return items.length > 0;
  }
);

const groupFilters = viewModelAttribute(
  'groupFilters',
  selectedFilterGroupItems,
  selectedFilterGroupItems => group => {
    const items = flatten(getChildren(group)).filter(
      filterIsActive(selectedFilterGroupItems)
    );

    return items.length === 0
      ? anything
      : items.reduce(
          (acc, { attributeName, value }) =>
            acc.or(
              where({
                [attributeName]: value,
              })
            ),
          never_matches
        );
  }
);

export const combinedGroupsFilter = viewModelAttribute(
  'combinedGroupsFilter',
  filterGroups,
  groupFilters,
  (filterGroups, groupFilters) =>
    filterGroups.reduce((acc, group) => acc.and(groupFilters(group)), anything)
);

export const rangesFilter = viewModelAttribute(
  'rangesFilter',
  thcFilter,
  cbdFilter,
  priceFilter,
  (thcFilter, cbdFilter, priceFilter) => thcFilter.and(cbdFilter).and(priceFilter)
);

export const productFilter = viewModelAttribute(
  'productFilter',
  combinedGroupsFilter,
  rangesFilter,
  (initialFilter, rangesFilter) => initialFilter.and(rangesFilter)
);

const accessors = {
  filterGroups,
  parentCategoriesMap,
  parentCategories,
  isAParentCategory,
  childCategoriesForParent,
  isActiveFilterGroup,
  hasActiveFilters,
  productFilter,
  thcRange,
  isExpanded,
  isSelected,
  cbdRange,
  priceRange,
  productsCBDRange,
  productsPriceRange,
  productsTHCRange,
  activeFilterGroupItems,
  allActiveItemsInGroup,
};

export const createViewModel = createViewModelMap(accessors);

export const __test__ = {
  createTHCFilter,
  createCBDFilter,
};
