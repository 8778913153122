import { createRequestHeaders, createPreOrderUri as createUpdateQuantityUri } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '', retailPreOrderId = -1 } = {}) =>
  createUpdateQuantityUri({ clientId, retailPreOrderId, uri: '/update-quantity' });

const createRequestPayload = ({ productId, quantity }) => ({
  orderItems: [
    {
      productId,
      qty: quantity,
    },
  ],
});

export default async ({
  clientId = '',
  menuId = '',
  retailPreOrderId = -1,
  productId,
  quantity,
} = {}) => {
  const r = await Axios.put(
    createUri({ clientId, retailPreOrderId }),
    createRequestPayload({ productId, quantity }),
    {
      headers: createRequestHeaders({ menuId }),
    }
  );

  return r.data;
};
