import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 mutation ($state: String!, $data: StoreFrontAccessRequest!) {
  storeFrontRequestAccess(state: $state, data: $data) {
    IsVerified
  }
}`;

export const realRequest = async ({
  state = '',
  contactInfo: { firstName = '', lastName = '', phone = '' } = {},
  vendorId = 0,
  storeFrontId = 0,
} = {}) => {
  const r = await runGQLRequest(
    'storeFrontRequestAccess',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        data: {
          StoreFrontId: storeFrontId,
          VendorId: vendorId,
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: phone,
        },
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.storeFrontRequestAccess;
};
