import config from 'config';
import { createGlobalStyle } from 'styled-components';

const liveGlobalStyle = createGlobalStyle`
  /* css reset */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  html,
  body {
    background: var(--gray-200);
    box-sizing: border-box;
    height: auto;
  }
  body {
    line-height: 1;
    font-family: InterUI, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    display: none;
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* app css */
  :root {
    --gray-900: #252526;
    --gray-700: #575759;
    --gray-600: #767678;
    --gray-500: #949495;
    --gray-400: #d7d7d9;
    --gray-300: #e6e6e8;
    --gray-200: #f2f2f2;
    --gray-100: #fafafa;
    --gray-000: #ffffff;
    --blue-900: #050428;
    --blue-700: #030047;
    --blue-500: #201d73;
    --blue-300: #0b3ed9;
    --blue-200: #3061f2;
    --blue-100: #7997f2;
    --blue-050: #f2f5ff;
    --purp-300: #473dcc;
    --purp-200: #6b57f8;
    --purp-100: #a49ff4;
    --green-300: #1b990f;
    --green-200: #40b335;
    --green-100: #6fcc66;
    --green-050: #f3fff2;
    --red-300: #cc141b;
    --red-200: #f2242b;
    --red-100: #ff4d53;
    --red-050: #fff3f2;
    --gold-300: #cc8800;
    --gold-200: #faad14;
    --gold-100: #ffc44d;
    --gold-050: #fffbe6;
  }

  div[role='group'][tabindex] {
    height: inherit;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #262526;
  }
  h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .regular-plus {
    font-size: 16px;
    line-height: 24px;
  }

  .semi-bold,
  .regular {
    font-size: 14px;
    line-height: 22px;
  }

  .light {
    opacity: 0.6;
  }

  .semi-bold {
    font-weight: 600;
  }

  .pointer {
    cursor: pointer;
  }

  input[type='checkbox'],
  input[type='color'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='file'],
  input[type='image'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='radio'],
  input[type='reset'],
  input[type='search'],
  input[type='submit'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'] {
    background: var(--gray-000);
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:active,
  .ant-select-selection:hover {
    box-shadow: 0 0 0 2px rgba(107, 87, 248, 0.2);
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:focus:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--purp-200);
    background: var(--purp-200);
  }

  .ant-select-item {
    color: var(--gray-700);
  }

  .ant-input-affix-wrapper:focus .ant-input,
  .ant-input-affix-wrapper:hover .ant-input {
    border-color: transparent;
    box-shadow: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .hidden {
    display: none;
  }

  .inner-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }

  @media (min-width: 1200px) {
    .inner-container {
      max-width: 1440px;
    }
  }

  .inner-container.browse-store-page {
    padding: 0;
    width: 100%;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background: var(--purp-100);
    color: #000;
  }

  .ant-picker:hover, .ant-picker-focused {
    border-color: var(--purp-100);
  }

  .ant-picker-today-btn {
    color: var(--purp-100);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--purp-100);
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: var(--purp-100);
  }
`;

const retailGlobalStyle = createGlobalStyle`
  /* css reset */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  html,
  body {
    background: #fff;
    box-sizing: border-box;
    height: auto;
  }
  body {
    line-height: 1;
    font-family: InterUI, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    display: none;
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* app css */
  :root {
    --gray-900: #252526;
    --gray-700: #575759;
    --gray-600: #767678;
    --gray-500: #949495;
    --gray-400: #d7d7d9;
    --gray-300: #e6e6e8;
    --gray-200: #f2f2f2;
    --gray-100: #fafafa;
    --gray-000: #ffffff;
    --blue-900: #050428;
    --blue-700: #030047;
    --blue-500: #201d73;
    --blue-300: #0b3ed9;
    --blue-200: #3061f2;
    --blue-100: #7997f2;
    --blue-050: #f2f5ff;
    --purp-300: #473dcc;
    --purp-200: #6b57f8;
    --purp-100: #a49ff4;
    --green-300: #1b990f;
    --green-200: #40b335;
    --green-100: #6fcc66;
    --green-050: #f3fff2;
    --red-300: #cc141b;
    --red-200: #f2242b;
    --red-100: #ff4d53;
    --red-050: #fff3f2;
    --gold-300: #cc8800;
    --gold-200: #faad14;
    --gold-100: #ffc44d;
    --gold-050: #fffbe6;
  }

  div[role='group'][tabindex] {
    height: inherit;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #262526;
  }
  h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .regular-plus {
    font-size: 16px;
    line-height: 24px;
  }

  .semi-bold,
  .regular {
    font-size: 14px;
    line-height: 22px;
  }

  .light {
    opacity: 0.6;
  }

  .semi-bold {
    font-weight: 600;
  }

  .pointer {
    cursor: pointer;
  }

  input[type='checkbox'],
  input[type='color'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='file'],
  input[type='image'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='radio'],
  input[type='reset'],
  input[type='search'],
  input[type='submit'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'] {
    background: var(--gray-000);
  }

  // ANT D ANT DESIGN overrides

  .ant-input:focus,
  .ant-input:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:active,
  .ant-select-selection:hover {
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:focus:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #000;
    background: #000;
  }

  .ant-select-item {
    color: var(--gray-700);
  }

  .ant-input-affix-wrapper:focus .ant-input,
  .ant-input-affix-wrapper:hover .ant-input {
    border-color: transparent;
    box-shadow: none;
  }


  // BUTTONS
  .ant-btn, .ant-btn:active, .ant-btn:focus {
    height: 40px;
    color: #fff;
    border-color: transparent;
    background-color: #000;
    border-radius: 3px;
  }
  .ant-btn:hover {
    color: #fff;
    opacity: 0.8;
    background-color: black;
    border-color: transparent;
  }
  .ant-btn-ghost {
    background-color: transparent;
    border-color: #d9d9d9;
    color: #262626;
    &:hover, &:active, &:focus {
      border-color: #000;
      color: #000;
      background-color: transparent;
    }
  }
  .ant-btn-primary {
    background-color: black;
    color: white;
    opacity: 1;
    border-color: transparent;
    &:hover, &:active, &:focus {
      opacity: 0.8;
      background-color: black;
      border-color: transparent;
    }
  }
  .ant-btn-link, .ant-btn-link:hover, .ant-btn-link:active {
    background-color: transparent;
    border-color: transparent;
    height: 40px;
    border-radius: 3px;
    color: #1890FF;
    cursor: pointer;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .hidden {
    display: none;
  }

  .inner-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }

  @media (min-width: 1200px) {
    .inner-container {
      max-width: 1440px;
    }
  }

  .inner-container.browse-store-page {
    padding: 0;
    width: 100%;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background: #000;
    color: #fff;
  }

  .ant-picker:hover, .ant-picker-focused {
    border-color: #000;
  }

  .ant-picker-today-btn {
    color: #000;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #000;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #000;
  }

  svg {
    color: #000;
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background: var(--gray-300);
  }
  .ant-picker-today-btn:hover {
    color: #000;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #000;
  }
`;

const GlobalStyle = config.retail ? retailGlobalStyle : liveGlobalStyle;

export default GlobalStyle;
