import { events, storeFrontRequestAccessKey } from './events';
import { logException, noOp } from 'utils/core/funcy';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { createTasks as createStoreTasks } from '../../store/tasks';
import { createViewModel } from './viewModel';
import { displayToast } from 'utils/toasts';
import { getCurrentState } from 'utils/redux';
import { newState } from './reducers';
import storeFrontRequestAccess from 'apiRequests/live/storeFrontRequestAccess';

export const createTasks = dispatch => {
  const { view: viewStore } = createStoreTasks(dispatch);

  const init = async () => {};

  const views = (id, state = getCurrentState()) => createViewModel(state, { id });

  const createStoreFront = ({ id, ...data }) => newState({ id, data });

  const storeFrontRequestAccessTasks = createRequestTasks(
    storeFrontRequestAccessKey,
    dispatch
  );

  const markAsPendingRequest = id => {
    dispatch(events.changedAccessStatus(id, 'WaitingForVerification'));
  };

  const requestStoreAccess = async storeFrontId => {
    const {
      shared: {
        accountSetup: { contactInfo, countryState },
        profile: {
          selectedVendor: { id: selectedVendorId },
        },
      },
    } = views({ storeFrontId });

    await logException(async () => {
      await storeFrontRequestAccessTasks.runRequest(
        storeFrontRequestAccess({
          state: countryState,
          contactInfo,
          vendorId: selectedVendorId,
          storeFrontId,
        }),
        { context: { storeFrontId } }
      );

      displayToast('Request sent');

      markAsPendingRequest(storeFrontId);
    });
  };

  const onAccessAction = async id => {
    let accessAction = noOp;

    const {
      storeFront: { canRequest, canView, awaitingVerification },
    } = views(id);

    if (canRequest || awaitingVerification) accessAction = requestStoreAccess;
    if (canView) accessAction = id => viewStore({ storeFrontId: id });

    await accessAction(id);
  };

  return {
    init,
    requestStoreAccess,
    createStoreFront,
    onAccessAction,
  };
};
