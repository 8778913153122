import base from 'paths.macro';

export const addProductToCartKey = `${base}-embeddedAddProductToCartStatus`;
export const removeItemFromPreOrderKey = `${base}-embeddedRemoveItemFromPreOrderStatus`;
export const updateItemQuantityForPreOrderKey = `${base}-embeddedUpdateItemQuantityForPreOrderStatus`;

export const eventMap = {
  orderSummaryUpdated: ({ retailPreOrderId, details }) => ({
    retailPreOrderId,
    details,
  }),

  orderCompleted: ({ retailPreOrderId }) => ({ retailPreOrderId }),
};
