import { or_criteria as any, criteria as where } from '@developwithpassion/match_js';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { curry, identity, pipe } from 'utils/core/funcy';
import { equal_to, not } from '@developwithpassion/matchers_js';
import {
  isSourced,
  missingCategory,
  missingParentCategory,
  validStringValue,
} from '../utils';
import { max, min } from '@developwithpassion/arrays_js';
import { reducerForUniqueInsertionOrderMap, reducerForUniqueMap } from '../../utils';
import { accessors as sharedAccessors, storeFrontId } from './impl';
import { createViewModel as createStoreFrontView } from '../../../browse-stores/storeFront/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getCurrentState } from 'utils/redux';
import { mapInitialDetails } from '../productMapper';
import { sortOrderList } from '../sortOrders';
export * from './impl';
export * from '../utils';
import { toHundredths } from 'utils/ui/mappers';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

let productsMap = new Map();

let rawProductListings = [];

const sortId = viewModelAttribute('sortId', ({ store: { sortId } }) => sortId, identity);

export const sortOrder = viewModelAttribute('sortOrder', sortId, sortId =>
  sortOrderList.find(where({ id: sortId }))
);

export const store = viewModelAttribute('store', ({ store }) => store, identity);

export const productsUpdated = viewModelAttribute(
  'productsUpdated',
  ({ store: { productsUpdated } }) => productsUpdated,
  identity
);

export const cart = viewModelAttribute('cart', ({ store: { cart } }) => cart, identity);

const storeFrontView = viewModelAttribute(
  'Store - Create StoreFront viewModelAttribute',
  storeFrontId,
  storeFrontId => createStoreFrontView(getCurrentState(), { id: storeFrontId })
);

export const storeFront = viewModelAttribute(
  'storeFrontView',
  storeFrontView,
  ({ storeFront }) => storeFront
);

export const hasStoreFront = viewModelAttribute(
  'hasStoreFront',
  storeFront,
  val => !!val
);

export const cartForStoreFront = viewModelAttribute(
  'cartForStoreFront',
  cart,
  storeFrontId,
  (cart, storeFrontId) => cart[storeFrontId] || {}
);

export const cartLineItemForProduct = viewModelAttribute(
  'cartLineItemForProduct',
  cartForStoreFront,
  cart => productId =>
    cart[productId] || { quantity: 0, note: '', shouldBeRemoved: false }
);

export const quantityForProduct = viewModelAttribute(
  'quantityForProduct',
  cartLineItemForProduct,
  cartLineItemForProduct => productId => cartLineItemForProduct(productId).quantity
);

export const noteForProduct = viewModelAttribute(
  'noteForProduct',
  cartLineItemForProduct,
  cartLineItemForProduct => productId => cartLineItemForProduct(productId).note
);

export const shouldBeRemovedFromCart = viewModelAttribute(
  'shouldBeRemovedFromCart',
  cartLineItemForProduct,
  cartLineItemForProduct => productId => cartLineItemForProduct(productId).shouldBeRemoved
);

export const textQuantityForProduct = viewModelAttribute(
  'textQuantityForProduct',
  quantityForProduct,
  quantityForProduct => id => {
    const val = quantityForProduct(id);

    return val > 0 ? `${val}` : '';
  }
);

const notApplicable = 'N/A';

const formatPercentageToHundredths = val => `${toHundredths(val)}%`;

const validRangeValue = not(equal_to(0));
const invalidRangeValue = not(validRangeValue);

const rangeDetail = ({ minField, maxField }) => ({
  [minField]: min,
  [maxField]: max,
}) => {
  if (invalidRangeValue(min) && invalidRangeValue(max)) return notApplicable;

  if (min === max) return formatPercentageToHundredths(max);

  return `${formatPercentageToHundredths(min)} - ${formatPercentageToHundredths(max)}`;
};

const invalidRange = { available: false, description: notApplicable };

const listingRangeDetail = (
  { minField, maxField },
  {
    minValueMapper = formatPercentageToHundredths,
    maxValueMapper = formatPercentageToHundredths,
  } = {}
) => ({ [minField]: min, [maxField]: max }) => {
  if (invalidRangeValue(min) && invalidRangeValue(max)) return invalidRange;

  if (invalidRangeValue(min) && validRangeValue(max))
    return { available: true, description: maxValueMapper(max) };

  if (validRangeValue(min) && invalidRangeValue(max))
    return { available: true, description: minValueMapper(min) };

  if (min === max) return { available: true, description: maxValueMapper(max) };

  return {
    available: true,
    description: `${minValueMapper(min)} - ${maxValueMapper(max)}`,
  };
};

const priceRangeDetail = ({ minField, maxField }) => ({
  [minField]: min,
  [maxField]: max,
}) => ({
  start: min,
  end: max,
});

const percentageFieldDetail = fieldName => ({ [fieldName]: value }) =>
  value === 0 ? notApplicable : formatPercentageToHundredths(value);

const cbdDetail = rangeDetail({ minField: 'minCBDMax', maxField: 'maxCBDMax' });

const thcDetail = rangeDetail({ minField: 'minTHCMax', maxField: 'maxTHCMax' });

const maxTotalOptionalDetail = percentageFieldDetail('maxTotalOptional');

const maxTotalTerpenesDetail = percentageFieldDetail('maxTotalTerpenes');

const withCBDDetail = data => ({
  ...data,
  cbdDetail: cbdDetail(data),
});

const withTHCDetail = data => ({
  ...data,
  thcDetail: thcDetail(data),
});

const withMaxTotalOptionalDetail = data => ({
  ...data,
  maxTotalOptionalDetail: maxTotalOptionalDetail(data),
});

const withMaxTotalTerpenesDetail = data => ({
  ...data,
  maxTotalTerpenesDetail: maxTotalTerpenesDetail(data),
});

const withSizeDisplayName = ({
  showSize,
  size,
  unitShortName,
  isSample = false,
  isEducationalSample = false,
  ...rest
}) => {
  const hasSample = isSample || isEducationalSample;
  const sampleText = isEducationalSample ? '(Edu S)' : isSample ? '(S)' : '';
  return {
    showSize,
    size,
    unitShortName,
    ...rest,
    sizeDisplayName: showSize
      ? `${size}${unitShortName}${hasSample ? ` ${sampleText}` : ''}`
      : hasSample
      ? sampleText
      : null,
  };
};

const withAdjustedSizeDetails = ({ isCountBased, size, ...rest }) => ({
  ...rest,
  size,
  isCountBased,
  showSize: size > 0,
  showUnitOfMeasureWithAvailable: !isCountBased,
});

const productMap = pipe(
  mapInitialDetails,
  withCBDDetail,
  withTHCDetail,
  withMaxTotalTerpenesDetail,
  withMaxTotalOptionalDetail,
  withAdjustedSizeDetails,
  withSizeDisplayName
);

const isAssociatedProduct = ({ id, sourcedProductId }) =>
  any({
    sourcedProductId,
    id: equal_to(sourcedProductId),
  }).and(where({ id: not(equal_to(id)) }));

const withLinkedProducts = (
  { id, sourcedProductId, isSourced, ...rest },
  _,
  productsList
) => {
  const sourceProductId = isSourced ? sourcedProductId : id;
  const associatedProducts = productsList.filter(
    isAssociatedProduct({ id, sourcedProductId: sourceProductId })
  );

  return {
    id,
    sourcedProductId,
    isSourced,
    associatedProducts,
    ...rest,
  };
};

const mapRawProducts = (products = []) =>
  products
    .map(productMap)
    .map(withLinkedProducts)
    .reduce((acc, val) => acc.set(val.id, val), new Map());

const setProductsMapDirectly = mapOfProducts => {
  productsMap = mapOfProducts;
};

const setProducts = (products = []) => {
  productsMap = mapRawProducts(products);
};

export const setProductListings = ({ products = [], listings = [] } = {}) => {
  const productsMap = products.reduce(
    reducerForUniqueInsertionOrderMap('id', identity),
    new Map()
  );

  rawProductListings = listings.slice(0).map((data, index) => ({
    ...data,
    id: `listing-${index + 1}`,
  }));

  rawProductListings.forEach(({ id: listingId, products }) => {
    products.forEach(({ id: productId }) => {
      productsMap.set(productId, { ...productsMap.get(productId), listingId });
    });
  });

  setProducts(Array.from(productsMap.values()));
};

export const clearProductListings = () => {
  setProductListings();
};

export const products = viewModelAttribute(
  'products',
  productsUpdated,
  () => productsMap
);

export const productsList = viewModelAttribute('productsList', products, val =>
  Array.from(val.values())
);

export const parentCategoriesMap = viewModelAttribute(
  'parentCategoriesMap',
  productsList,
  val =>
    val.reduce(
      reducerForUniqueInsertionOrderMap(
        'productCategoryParentId',
        ({ productCategoryParentId, productCategoryParentName }) => ({
          id: productCategoryParentId,
          key: productCategoryParentId,
          title: productCategoryParentName,
          name: productCategoryParentName,
        })
      ),
      new Map()
    )
);

export const parentCategories = viewModelAttribute(
  'parentCategories',
  parentCategoriesMap,
  val => Array.from(val.values())
);

export const parentCategoryIds = viewModelAttribute(
  'parentCategoryIds',
  parentCategories,
  val => val.map(({ id }) => id)
);

const listingPercentageRangeBuilder = listingRangeDetail(
  { minField: 'start', maxField: 'end' },
  { minValueMapper: toHundredths, maxValueMapper: formatPercentageToHundredths }
);

const priceRangeRangeBuilder = priceRangeDetail({ minField: 'start', maxField: 'end' });

const rangeFields = {
  priceRange: {
    start: ({ price }) => price,
    rangeBuilder: priceRangeRangeBuilder,
  },
  thcRange: {
    start: ({ minTHCMax }) => minTHCMax,
    end: ({ maxTHCMax }) => maxTHCMax,
  },
  cbdRange: {
    start: ({ minCBDMax }) => minCBDMax,
    end: ({ maxCBDMax }) => maxCBDMax,
  },
  totalOptionalRange: {
    start: ({ minTotalOptional }) => minTotalOptional,
    end: ({ maxTotalOptional }) => maxTotalOptional,
  },
  terpenesRange: {
    start: ({ minTotalTerpenes }) => minTotalTerpenes,
    end: ({ maxTotalTerpenes }) => maxTotalTerpenes,
  },
};

const _buildRangeSummaries = products =>
  Object.entries(rangeFields).reduce(
    (
      acc,
      [key, { start, end = start, rangeBuilder = listingPercentageRangeBuilder } = {}]
    ) => ({
      ...acc,
      [key]: rangeBuilder({
        start: min(start, products),
        end: max(end, products),
      }),
    }),
    {}
  );

const mapListing = ({ products, ...rest }) => {
  products = products.map(({ id }) => productsMap.get(id));

  const parentCategoryId = products.length > 0 ? products[0].productCategoryParentId : 0;
  const [{ videoEmbedLink = '' } = {}] =
    products.length > 0
      ? products.filter(
          where({
            videoEmbedLink: validStringValue,
          })
        )
      : [];

  const sizes = products
    .map(({ sizeDisplayName }) => sizeDisplayName)
    .filter(val => !!val);

  const sortFields = sortOrderList.reduce(
    (acc, { id, attributeResolver, reducer }) => ({
      ...acc,
      [id]: reducer(attributeResolver, products),
    }),
    {}
  );

  const ranges = _buildRangeSummaries(products);

  return {
    ...rest,
    parentCategoryId,
    sortFields,
    sizes,
    products,
    ...ranges,
    videoEmbedLink,
  };
};

const calculateAmountToTakeFromBulkSource = ({
  isSourced,
  size,
  cartQuantity,
  available,
}) => {
  if (!isSourced) {
    return cartQuantity || 0;
  }
  return cartQuantity > available ? size * Math.abs(cartQuantity - available) : 0;
};

const withCartInfo = ({
  quantityForProduct,
  noteForProduct,
  shouldBeRemovedFromCart,
}) => ({ id, price, ...rest }) => ({
  id,
  price,
  ...rest,
  cartQuantity: quantityForProduct(id),
  total: quantityForProduct(id) * price,
  noteCanBeAdded: quantityForProduct(id) > 0,
  noteForProduct: noteForProduct(id),
  hasNote: noteForProduct(id).length > 0,
  shouldBeRemoved: shouldBeRemovedFromCart(id),
});

const calculateAvailableQuantity = (
  product,
  bulkAmountTakenFromOtherAssociatedProducts
) => {
  const { isSourced, size, bulkGramsAvailable, available, cartQuantity } = product;

  const currentProductReserved = calculateAmountToTakeFromBulkSource(product);
  const currentRemaining = Math.max(0, available - cartQuantity);
  const sizeForCalculation = isSourced ? size : 1;

  const totalReserved =
    currentProductReserved + bulkAmountTakenFromOtherAssociatedProducts;

  const startingAmount = isSourced ? bulkGramsAvailable : available;
  const maxAmountInCart = Math.max(
    0,
    isSourced
      ? available +
          (bulkGramsAvailable - bulkAmountTakenFromOtherAssociatedProducts) /
            sizeForCalculation
      : available
  );

  const fractionalRemainingAvailable =
    Math.max(0, startingAmount - totalReserved) / sizeForCalculation;

  const calculatedRemainingAvailable = isSourced
    ? Math.floor(fractionalRemainingAvailable) + currentRemaining
    : fractionalRemainingAvailable;

  const remaining = Math.max(0, calculatedRemainingAvailable);

  return {
    calculatedRemainingAvailable: isSourced ? remaining : calculatedRemainingAvailable,
    maxAmountInCart: isSourced ? Math.floor(maxAmountInCart) : maxAmountInCart,
  };
};

export const withCalculatedAvailability = curry((cartInfoMapper, product) => {
  const {
    cartQuantity = 0,
    available,
    associatedProducts = [],
    bulkGramsAvailable,
  } = product;

  if (associatedProducts.length === 0 && bulkGramsAvailable === 0)
    return {
      ...product,
      calculatedRemainingAvailable: Math.max(0, available - cartQuantity),
      maxAmountInCart: Math.max(0, available),
    };

  const bulkAmountTakenFromOtherAssociatedProducts = associatedProducts
    .map(cartInfoMapper)
    .reduce(
      (acc, { cartQuantity, size, isSourced, available }) =>
        acc +
        calculateAmountToTakeFromBulkSource({ isSourced, size, cartQuantity, available }),
      0
    );

  return {
    ...product,
    ...calculateAvailableQuantity(product, bulkAmountTakenFromOtherAssociatedProducts),
  };
});

export const withExtraDetails = ({
  calculatedRemainingAvailable,
  showUnitOfMeasureWithAvailable,
  unitShortName,
  cartQuantity,
  maxAmountInCart,
  ...rest
}) => {
  const value =
    calculatedRemainingAvailable % 1 === 0
      ? calculatedRemainingAvailable
      : toHundredths(calculatedRemainingAvailable);

  const availabilityDescription = showUnitOfMeasureWithAvailable
    ? `${value}${unitShortName}`
    : value;

  const isRemainingInventoryOversold = cartQuantity > maxAmountInCart;

  return {
    calculatedRemainingAvailable,
    showUnitOfMeasureWithAvailable,
    unitShortName,
    cartQuantity,
    maxAmountInCart,
    ...rest,
    availabilityDescription,
    isRemainingInventoryOversold,
  };
};

export const productWithFullDetails = viewModelAttribute(
  'productWithFullDetails',
  cart,
  products,
  quantityForProduct,
  noteForProduct,
  shouldBeRemovedFromCart,
  (_, products, quantityForProduct, noteForProduct, shouldBeRemovedFromCart) => id =>
    [products.get(id)]
      .map(
        withCartInfo({
          quantityForProduct,
          noteForProduct,
          shouldBeRemovedFromCart,
        })
      )
      .map(
        withCalculatedAvailability(
          withCartInfo({ quantityForProduct, noteForProduct, shouldBeRemovedFromCart })
        )
      )
      .map(withExtraDetails)[0]
);

export const rawProductListingsList = viewModelAttribute(
  'rawProductListingsList',
  productsUpdated,
  () => rawProductListings.map(mapListing)
);

export const productListings = viewModelAttribute(
  'productListings',
  cart,
  rawProductListingsList,
  productWithFullDetails,
  (_, list, details) =>
    list.map(({ products, ...rest }) => ({
      ...rest,
      products: products.map(({ id }) => details(id)),
    }))
);

export const listingsMap = viewModelAttribute(
  'productListingsMap',
  productListings,
  val => val.reduce(reducerForUniqueMap('id', identity), {})
);

export const createRange = ({ start, end }) => ({
  start,
  end,
});

const createProductsRangeView = (name, { startSelector, endSelector }) =>
  viewModelAttribute(name, productsList, items => {
    const start = min(startSelector, items);
    const end = max(endSelector, items);

    return createRange({ start, end });
  });

export const productsTHCRange = createProductsRangeView('productsTHCRange', {
  startSelector: ({ minTHCMax }) => minTHCMax,
  endSelector: ({ maxTHCMax }) => maxTHCMax,
});

export const productsCBDRange = createProductsRangeView('productsCBDRange', {
  startSelector: ({ minCBDMax }) => minCBDMax,
  endSelector: ({ maxCBDMax }) => maxCBDMax,
});

export const productsPriceRange = createProductsRangeView('productsPriceRange', {
  startSelector: ({ price }) => price,
  endSelector: ({ price }) => price,
});

const productModalVisible = viewModelAttribute(
  'Store - Product Modal Visible',
  ({ store: { productModalVisible }}) => productModalVisible,
  identity
);

export const accessors = {
  productsList,
  productsTHCRange,
  productsCBDRange,
  productsPriceRange,
  textQuantityForProduct,
  productModalVisible,
  ...sharedAccessors(),
};

export const createViewModel = createViewModelMap(accessors);

export default createViewModel;

export const __test__ = {
  mapInitialDetails,
  calculateAmountToTakeFromBulkSource,
  calculateAvailableQuantity,
  cbdDetail,
  createRange,
  isSourced,
  productMap,
  withAdjustedSizeDetails,
  withCBDDetail,
  withCartInfo,
  withLinkedProducts,
  withSizeDisplayName,
  withTHCDetail,
  setProductsMapDirectly,
  thcDetail,
  maxTotalTerpenesDetail,
  maxTotalOptionalDetail,
  missingParentCategory,
  missingCategory,
};
