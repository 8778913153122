import CartSummary from '../../../../cart/components/Summary';
import { storeInfo } from '../../styles';

const StoreInfo = () => (
        <div className={storeInfo}>
            <CartSummary />
        </div>
);

export default StoreInfo;
