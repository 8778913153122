import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const createStoreFrontId = ({ clientId, menuId }) => `${clientId}-${menuId}`;

export const storeFrontId = viewModelAttribute(
  'storeFrontId',
  ({ store: { clientId, menuId } }) => createStoreFrontId({ clientId, menuId }),
  identity
);

export const currentStoreDisplayArgs = viewModelAttribute(
  'currentStoreDisplayArgs',
  ({ store: { clientId, menuId } }) => ({ menuId, clientId }),
  identity
);

const clientId = viewModelAttribute(
  'retail - clientId',
  ({ store: { clientId } }) => clientId,
  identity
);

const menuId = viewModelAttribute(
  'retail - menuId',
  ({ store: { menuId } }) => menuId,
  identity
);

export const accessors = () => ({
  clientId,
  menuId,
});
