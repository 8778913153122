import { createPreOrderUri, createRequestHeaders } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '', retailPreOrderId = -1, orderItemId = '' } = {}) =>
  createPreOrderUri({ clientId, retailPreOrderId, uri: `/remove-item/${orderItemId}` });

export default async ({
  clientId = '',
  menuId = '',
  retailPreOrderId = -1,
  orderItemId = '',
} = {}) => {
  const r = await Axios.put(
    createUri({ clientId, retailPreOrderId, orderItemId }),
    {},
    {
      headers: createRequestHeaders({ menuId }),
    }
  );

  return r.data;
};
