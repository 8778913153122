import {
  addProductToCartKey,
  removeItemFromPreOrderKey,
  updateItemQuantityForPreOrderKey,
} from '../../../reducers/events/impl/embedded';
import {
  data,
  remove as removeItemFromLocalStorage,
  update as updateLocalStorage,
} from 'utils/localStorage';
import _ from 'lodash';
import addItemToPreOrderRequest from 'apiRequests/embedded-menu/preOrder/addItem';
import { changeOrderSummaryTo } from 'routes/store/embeddedCart/embeddedOrderSummary/controller.orderComplete';
import { createViewModel as createOrderSummaryViews } from 'routes/store/embeddedCart/embeddedOrderSummary/viewModel';
import createPreOrderRequest from 'apiRequests/embedded-menu/preOrder/create';
import { createTasks as createRequestTasksImpl } from 'state/slice/request/tasks';
import { createStoreFrontId } from '../../../shared/viewModel/impl/embedded';
import delayedBehavior from '../../../../../utils/core/delayedBehaviour';
import { events } from '../../../reducers/events';
import { getCurrentState } from 'utils/redux';
import loadPreOrderRequest from 'apiRequests/embedded-menu/preOrder/get';
import { noOp } from 'utils/core/funcy';
import removeItemFromPreOrderRequest from 'apiRequests/embedded-menu/preOrder/removeItem';
import { routes } from 'router';
import updateItemQuantityForPreOrderRequest from 'apiRequests/embedded-menu/preOrder/updateQuantity';
const { orderComplete, store, cart, customerInfo } = routes;

export const createStoreListingArgs = ({ views }) => views().currentStoreDisplayArgs;

export const createViewArgs = ({ props }) => {
  const { clientId, menuId } = props;

  return { clientId, menuId };
};

export const display = store;

const reloadOrder = async ({ retailPreOrderId, menuId, clientId }, { dispatch }) => {
  const order = await loadPreOrderRequest({ retailPreOrderId, menuId, clientId });
  dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
};

export const createView = ({
  clearProductListings,
  updateProducts,
  initializeCartForStoreFront,
  reloadProducts,
  changeDetails,
  dispatch,
}) => async ({ clientId, menuId }) => {
  updateProducts(clearProductListings);

  initializeCartForStoreFront(createStoreFrontId({ clientId, menuId }));

  await reloadProducts();

  const { retailPreOrderId } = data();

  if (retailPreOrderId) {
    await reloadOrder({ retailPreOrderId, menuId, clientId }, { dispatch });
    changeDetails({ retailPreOrderId });
  }

  changeDetails({
    storeFrontId: createStoreFrontId({ clientId, menuId }),
    initialized: true,
  });

  await store({ clientId, menuId });
};

export const initializeStoreDetails = ({ props, changeDetails }) => {
  const { clientId, menuId } = props;

  changeDetails({ menuId, clientId });
};

const dependencies = {
  createRequestTasks: createRequestTasksImpl,
  createOrderSummaryViews: (state = getCurrentState()) => createOrderSummaryViews(state),
};

export const createTasks = (
  { dispatch, changeDetails, views = noOp },
  { allDependencies = dependencies } = {}
) => {
  const { createRequestTasks, createOrderSummaryViews } = allDependencies;

  const addItemToPreOrderTasks = createRequestTasks(addProductToCartKey, dispatch);
  const removeItemFromPreOrderTasks = createRequestTasks(
    removeItemFromPreOrderKey,
    dispatch
  );
  const updateItemQuantityForPreOrderTasks = createRequestTasks(
    updateItemQuantityForPreOrderKey,
    dispatch
  );

  const retailDetails = () => {
    const { clientId, menuId, retailPreOrderId, quantityForProduct } = views();
    return { clientId, menuId, retailPreOrderId, quantityForProduct };
  };

  const createPreOrderWithFirstProduct = async ({ productId, quantity }) => {
    const data = await createPreOrderRequest({
      productId,
      quantity,
      ...retailDetails(),
    });

    const { order } = data;

    const { objectId: retailPreOrderId } = order;

    changeDetails({ retailPreOrderId });
    updateLocalStorage(() => ({ retailPreOrderId }));

    return data;
  };

  const setProductListings = ({
    supportsPreOrders = false,
    supportsDelivery = false,
    storeName = '',
    storeType = 'Recreational',
    storeLocation = {},
  } = {}) => {
    changeDetails({
      supportsPreOrders,
      supportsDelivery,
      storeName,
      storeType,
      storeLocation,
    });
  };

  const updateItemQuantityForPreOrder = async ({ productId, newQuantity }) => {
    const { retailPreOrderId } = views();

    const { order } = await updateItemQuantityForPreOrderTasks.runRequest(
      updateItemQuantityForPreOrderRequest({
        productId,
        quantity: newQuantity,
        ...retailDetails(),
      })
    );

    dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
  };

  const checkForExistingProductId = async ({ productId, quantity }) => {
    const { clientId, menuId, retailPreOrderId } = views();

    const preOrderItems = await loadPreOrderRequest({
      retailPreOrderId,
      menuId,
      clientId,
    });

    const productInPreOrder = _.filter(preOrderItems.items, [
      'product.objectId',
      productId,
    ]);

    const { unitWeight, uom } = [ productInPreOrder ];

    return productInPreOrder.length > 0 && uom === 'Grams' ? quantity + unitWeight : false;
  };

  const getQuantityForProduct = () => {
    const { quantityForProduct } = retailDetails();
    return quantityForProduct;
  };

  const addItemToPreOrder = async ({ productId, quantity, variantId }) => {
    const { preOrderHasBeenCreated, retailPreOrderId } = views();

    if (!preOrderHasBeenCreated) {
      const { order } = await addItemToPreOrderTasks.runRequest(
        createPreOrderWithFirstProduct({
          productId,
          quantity,
          ...retailDetails(),
        })
      );
      changeDetails({ lastVariantAdded: variantId });
      changeDetails({ productModalVisible: true });

      const delayedHideProductModal = delayedBehavior(() => {
        changeDetails({ productModalVisible: false });
      }, 6000);

      delayedHideProductModal.restart();

      dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
    }

    if (preOrderHasBeenCreated) {
      const combinedQuantity = await checkForExistingProductId({ productId, quantity });
      if (combinedQuantity > quantity) {
        const { order } = await updateItemQuantityForPreOrderTasks.runRequest(
          updateItemQuantityForPreOrderRequest({
            productId,
            quantity: combinedQuantity,
            ...retailDetails(),
          })
        );

        // TO DO modal for updating quantity in cart

        dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
      } else {
        const { order } = await addItemToPreOrderTasks.runRequest(
          addItemToPreOrderRequest({
            productId,
            quantity,
            ...retailDetails(),
          })
        );
        changeDetails({ lastVariantAdded: variantId });
        changeDetails({ productModalVisible: true });

        const delayedHideProductModal = delayedBehavior(() => {
          changeDetails({ productModalVisible: false });
        }, 6000);

        delayedHideProductModal.restart();

        dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
      }
    }
  };

  const completeOrder = async orderNumber => {
    const { clientId, menuId, retailPreOrderId } = views();
    removeItemFromLocalStorage({ retailPreOrderId });
    const orderSummary = createOrderSummaryViews();
    dispatch(events.orderCompleted({ retailPreOrderId }));
    changeOrderSummaryTo(orderSummary);

    await orderComplete({
      clientId,
      menuId,
      orderNumber,
    });
  };

  const removeItemFromPreOrder = async ({ orderItemId }) => {
    const { retailPreOrderId } = views();

    const { order } = await removeItemFromPreOrderTasks.runRequest(
      removeItemFromPreOrderRequest({
        orderItemId,
        ...retailDetails(),
      })
    );

    dispatch(events.orderSummaryUpdated({ retailPreOrderId, details: order }));
  };

  const init = async () => {};

  const startCheckout = async () => {
    const { currentStoreDisplayArgs } = views();

    await customerInfo(currentStoreDisplayArgs);
  };

  const displayCart = async () => {
    const { currentStoreDisplayArgs } = views();

    await cart(currentStoreDisplayArgs);
  };

  const viewCart = async () => {
    await displayCart();
  };

  return {
    getQuantityForProduct,
    addItemToPreOrder,
    removeItemFromPreOrder,
    updateItemQuantityForPreOrder,
    setProductListings,
    completeOrder,
    init,
    viewCart,
    startCheckout,
  };
};
