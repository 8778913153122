import { WebAuth } from 'auth0-js';
import config from 'config';
export { default as getUserInfo } from 'apiRequests/live/getStoreFrontUserProfile';
import signupRequest from 'apiRequests/live/signUp';
import updateUserProfileRequest from 'apiRequests/live/updateStoreFrontUserProfile';

const STATIC_NONCE = 'a100de4e7ebd4a7abfe081f78c8ce6c8';

let auth0WebClient;
let sessionInfo = null;

const scopes = 'openid profile email user_metadata';

const authOptions = () => ({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId,
  redirectUri: config.auth0.redirectUri,
  audience: config.auth0.audience,
  responseType: 'token id_token',
  scope: scopes,
  nonce: STATIC_NONCE,
});

export const normalizeEmail = email => email.trim().toLowerCase();

export const initAuthClient = async () => {
  if (auth0WebClient) return;

  auth0WebClient = new WebAuth({
    ...authOptions(),
  });
};

const setSessionInfo = details => {
  sessionInfo = details;
};

const clearSessionInfo = () => {
  sessionInfo = null;
};

export const getLocalUserInfo = () =>
  sessionInfo ? sessionInfo.idTokenPayload : { email: '' };

export const startLogin = async (startingUri = '/') => {
  await new Promise((resolve, reject) => {
    auth0WebClient.authorize(
      {
        appState: { startingUri },
      },
      (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
};

export const signup = async ({ email, password }) =>
  await signupRequest({ email: normalizeEmail(email), password });

const checkSession = async () => {
  try {
    const result = await new Promise((resolve, reject) => {
      auth0WebClient.checkSession(
        {
          scope: scopes,
        },
        (err, sessionResult) => {
          if (err) {
            reject(err);
            return;
          } else {
            resolve(sessionResult);
          }
        }
      );
    });
    setSessionInfo(result);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    clearSessionInfo();
  }
};

export const resumeLogin = async () => {
  await checkSession();
  return !!sessionInfo;
};

export const loggedIn = () => !!sessionInfo;

export const getAuthToken = async () => {
  const { accessToken = '' } = sessionInfo;

  return accessToken;
};

export const isAuthenticated = loggedIn;

export const completeLogin = async () => {
  try {
    const result = await new Promise((resolve, reject) => {
      auth0WebClient.parseHash({}, (err, result) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          reject(err);
          return;
        }
        resolve(result);
      });
    });
    setSessionInfo(result);
    return { success: true, appState: result.appState, result };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const logout = async () => {
  await auth0WebClient.logout({
    returnTo: config.auth0.logoutUri,
    federated: false,
  });
};

export const updateUserProfile = async (details = {}) =>
  await updateUserProfileRequest(details);

export const __test__ = {
  setSessionInfo,
  clearSessionInfo,
};
