import _createHash from 'md5';

const _map = ({ name, categoryId, strain }, createHash) => {
  const key = [name, categoryId, strain].join('-');
  return createHash(key);
};

export default product => _map(product, _createHash);

export const __test__ = {
  _map,
};
