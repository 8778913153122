import AddToCartGroup from '../../../AddToCartGroup';
import React from 'react';

import { noOp } from 'utils/core/funcy';

const ListingCard = ({
  listing: { products = [], imageUrl = '' } = {},
  changeCartQuantity,
  productWithFullDetails = noOp,
  textQuantityForProduct = noOp,
  supportsPreOrders = false,
  productModalVisible = false,
  lastVariantAdded = '',
  addItemToPreOrder = noOp,
  viewCart = noOp,
  startCheckout = noOp,
} = {}) => {
  const productTemplate = ({ id = '' } = {}) => {
    const {
      calculatedRemainingAvailable,
      cartQuantity,
      total,
      variants,
      uom,
      brand,
      name,
    } = productWithFullDetails(id);
    const addToCartProps = {
      id,
      calculatedRemainingAvailable,
      cartQuantity,
      total,
      textQuantityForProduct,
      changeCartQuantity,
      variants,
      supportsPreOrders,
      productModalVisible,
      lastVariantAdded,
      imageUrl,
      addItemToPreOrder,
      uom,
      brand,
      name,
      viewCart,
      startCheckout,
    };
    return <AddToCartGroup {...addToCartProps} sizes />;
  };

  return <div className="add-to-cart-container">{products.map(productTemplate)}</div>;
};

export default ListingCard;
