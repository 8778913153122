import Axios from 'axios';
import { authOptions } from 'utils/xhrs/authToken';
import config from 'config';
import { mapToLegacyInput } from 'utils/xhrs/legacyGateway/resultMapper';

const query = `
 mutation ($state: String!, $data: StoreFrontCreateOrder!) {
  storeFrontCreatePurchaseOrder(state: $state, data: $data) {
    Id,
    OrderNum
  }
}`;

export const request = async (state, data) => {
  const { lineItems } = data;
  const r = await Axios.post(
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        data: mapToLegacyInput({ ...data, lineItems: lineItems.map(mapToLegacyInput) }),
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.storeFrontCreatePurchaseOrder;
};
