import { component, hocWithMappings as hoc } from 'utils/views';
import Template from './template';
import controller from './controller';

const TemplateWithController = hoc(
  'AddToCartGroup - Remapping data to view specific mappings',
  controller
);

export default component({
  Template: TemplateWithController(Template),
});
