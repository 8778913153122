import Template from '../../template';
import { component } from 'utils/views';
import { createTasks } from './tasks';
import { createViewModel } from '../../../../viewModel';

export default component({
  Template,
  createViewModel,
  createTasks,
});
