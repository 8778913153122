import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';
import { noOp } from 'utils/core/funcy';

export default ({
  item: { id: orderItemId = 0 } = {},
  removeItemFromPreOrder = noOp,
} = {}) => {
  const onClick = createEventHandler(() => {
    removeItemFromPreOrder({ orderItemId });
  });

  return {
    onClick,
  };
};
