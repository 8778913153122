/* eslint-disable */
import * as styles from './styles';

import { Button, Card, Tooltip } from 'antd';

import React from 'react';
import RequestStatus from 'components/RequestStatus';
import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';
import { noOp } from 'utils/core/funcy';
import placeholderImage from 'src/images/white-image.png';

const StoreCard = ({
  requestAccessStatus = {},
  storeFront: {
    id = 0,
    name = '',
    accessTitle = '',
    logoUrl = '',
    canRequest = true,
    awaitingVerification = false,
    canView = false,
  } = {},
  onAccessAction = noOp,
} = {}) => {
  const onAccessActionTrigger = createEventHandler(() => onAccessAction(id));

  const requestActionTemplate = title => (
    <div className="flex-container">
      <Button type="primary" size="small" block>
        {title}
      </Button>
    </div>
  );

  const requestRunningTemplate = (
    <Button type="primary" size="small" block loading className="loading-button">
      Sending...
    </Button>
  );

  const errorActionTemplate = (
    <div className="flex-container error">
      <h5>Error</h5>
      <Button type="primary" size="small">
        Try Again
      </Button>
    </div>
  );

  const pendingActionTemplate = (
    <div className="flex-container pending">
      <h5>Pending</h5>
      <Button type="primary" size="small">
        Resend
      </Button>
    </div>
  );

  const buildDetailsTemplate = title => {
    if (awaitingVerification) return pendingActionTemplate;
    if (canView || canRequest) return requestActionTemplate(title);

    return null;
  };

  const detailsTemplate = buildDetailsTemplate(accessTitle);

  const actionTemplate = (
    <RequestStatus
      status={requestAccessStatus}
      inactiveTemplate={detailsTemplate}
      completedTemplate={detailsTemplate}
      startedTemplate={requestRunningTemplate}
      failedTemplate={errorActionTemplate}
    />
  );

  const cardTemplate = (
    <div className={styles.storeCardContainer}>
      <Card
        className={styles.storeCard}
        hoverable
        cover={
          <img
            className="logo-img"
            alt={logoUrl ? name : 'Placeholder Image'}
            src={logoUrl || placeholderImage}
          />
        }
        onClick={onAccessActionTrigger}
      >
        <Card.Meta
          description={
            name.length > 30 ? (
              <Tooltip title={name} mouseEnterDelay={1}>
                <p className="regular-plus ellipsis">{name}</p>
              </Tooltip>
            ) : (
              <p className="regular-plus">{name}</p>
            )
          }
        />
        {!canView ? <div className="card-actions">{actionTemplate}</div> : null}
      </Card>
    </div>
  );

  return cardTemplate;
};

export default StoreCard;
