module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f826e08f494e48049fc662805c610a98@o40179.ingest.sentry.io/2469925"},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GrowFlow Live","short_name":"GrowFlow Live","start_url":"/","background_color":"#3f2666","theme_color":"#3f2666","display":"minimal-ui","include_favicon":false,"icon":"src/images/growflow-monogram-logo.svg","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"9c91b78b52bdf5f5712fd110205afb26"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
