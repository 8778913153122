import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 query {
  getJurisdictions {
    RegionName,
    RegionAbbreviation
  }
}`;

export default async () => {
  const r = await runGQLRequest(
    'getJurisdictions',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {},
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.getJurisdictions;
};
