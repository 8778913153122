import React, { Fragment } from 'react';
import {
  checkoutContainer as checkoutContainerStyles,
  checkoutWrapper as checkoutWrapperStyles,
} from '../embeddedOrder/styles';
import CartItemCard from '../components/CartItemCard';
import { Divider } from 'antd';
import EmbeddedOrderSummary from '../embeddedOrderSummary';
import FullScreenSpinner from 'components/FullScreenSpinner';
import { LeftOutlined } from '@ant-design/icons';
import OrderProgress from 'components/OrderProgress';
import PoweredByGrowFlow from 'components/PoweredByGrowFlow';
import RequestStatus from 'components/RequestStatus';
import { noOp } from 'utils/core/funcy';

const EmbeddedCart = (props = {}) => {
  const {
    updateItemQuantityForPreOrderStatus = {},
    backToStore = noOp,
    isDesktop = true,
    groupedCartItems = [],
  } = props;

  const backToProductsTemplate = (
    <div className="back-cart" onClick={backToStore}>
      <LeftOutlined className="icon" />
      <span className="semi-bold">Back to products</span>
    </div>
  );

  const cartHeaderTemplate = (
    <div className="cart-header-container">
      <OrderProgress steps={3} currentStep={1} />
      <div className="greeting">
        <h2>Your Cart</h2>
      </div>

      {isDesktop ? (
        <div className="desktop-header">
          {backToProductsTemplate}
        </div>
      ) : (
        <Fragment>
          {backToProductsTemplate}
        </Fragment>
      )}
    </div>
  );

  const cartItemTemplate = itemGroup => <CartItemCard item={itemGroup} {...props} />;

  const nonBusyTemplate = (
    <Fragment>
      {cartHeaderTemplate}
      <div className="cart-overview-container">
        <div className="cart-overview-products">
          {groupedCartItems.map(productIdGroup => cartItemTemplate(productIdGroup))}
        </div>
        <div className="cart-overview-totals">
          <EmbeddedOrderSummary />
        </div>
      </div>
    </Fragment>
  );

  const busyTemplate = (
    <Fragment>
      <FullScreenSpinner />
      {nonBusyTemplate}
    </Fragment>
  );

  return (
    <>
      <div className={checkoutWrapperStyles}>
        <div className={checkoutContainerStyles}>
          <RequestStatus
            status={updateItemQuantityForPreOrderStatus}
            inactiveTemplate={nonBusyTemplate}
            completedTemplate={nonBusyTemplate}
            startedTemplate={busyTemplate}
            failedTemplate={nonBusyTemplate}
          />
        </div>
      </div>
      <Divider />
      <PoweredByGrowFlow />
    </>
  );
};

export default EmbeddedCart;
