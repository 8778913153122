import { routes } from 'router';

const { cart } = routes;

export default ({ clientId, menuId }) => {
  const onBackToCart = async () => {
    await cart({ clientId, menuId });
  };

  return {
    onBackToCart,
    onEditOrder: onBackToCart,
  };
};
