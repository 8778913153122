import {
  createOrderView,
  invalidCartProducts,
  orderLineItemMap,
  orderNote,
  productsForOrder,
  shared,
  store,
  storeFrontCreatePurchaseOrderRequestStatus,
} from './impl';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import {
  currentStoreDisplayArgs,
  parentCategories,
  parentCategoryIds,
  productWithFullDetails,
  accessors as sharedAccessors,
  storeFront,
  storeFrontId,
} from '../../shared/viewModel';
import { createViewModel as createAccountSetupViewModel } from '../../../account-setup/viewModel';
import { equal_to } from '@developwithpassion/matchers_js';
import { fileAbsolute } from 'paths.macro';
import { sort } from '@developwithpassion/comparers_js';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const cartLineItems = viewModelAttribute(
  'cartLineItems',
  productsForOrder,
  productWithFullDetails,
  (items, details) => items.map(({ id }) => details(id))
);

const orderLineItems = viewModelAttribute('orderLineItems', cartLineItems, items =>
  items.map(orderLineItemMap)
);

const totalItems = viewModelAttribute('totalItems', cartLineItems, items =>
  items.reduce((acc, { cartQuantity }) => acc + cartQuantity, 0)
);

const totalLineItems = viewModelAttribute(
  'totalLineItems',
  cartLineItems,
  items => items.length
);

const order = createOrderView(orderLineItems);

const isEmpty = viewModelAttribute('isEmpty', totalItems, equal_to(0));

const sortedParentCategories = viewModelAttribute(
  'sortedParentCategories',
  parentCategories,
  parentCategoryIds,
  (parentCategories, parentCategoryIds) =>
    parentCategories.sort(sort.by_fixed('id', parentCategoryIds))
);

const categoryTotals = viewModelAttribute(
  'categoryTotals',
  productsForOrder,
  sortedParentCategories,
  (products, sortedParentCategories) => {
    const totals = sortedParentCategories.reduce(
      (accumulator, { id, name }) => accumulator.set(id, { name, total: 0, quantity: 0 }),
      new Map()
    );
    products.forEach(({ productCategoryParentId, price, quantity }) => {
      const item = totals.get(productCategoryParentId);
      item.total += price * quantity;
      item.quantity += quantity;
    });
    return Array.from(totals.values()).filter(({ quantity }) => quantity !== 0);
  }
);

const cartTotal = viewModelAttribute(
  'cartTotal',
  productsForOrder,
  productWithFullDetails,
  (items, details) => items.reduce((acc, { id }) => acc + details(id).total, 0)
);

const strictOrderMode = viewModelAttribute(
  'strictOrderMode',
  storeFront,
  storeFront => storeFront.minimumOrderValueMode === 'Strict'
);

const canPlaceOrder = viewModelAttribute(
  'canPlaceOrder',
  productsForOrder,
  invalidCartProducts,
  cartTotal,
  storeFront,
  strictOrderMode,
  (productsForOrder, invalidCartProducts, cartTotal, storeFront, strictOrderMode) =>
    productsForOrder.length > 0 &&
    invalidCartProducts.length === 0 &&
    (!strictOrderMode || cartTotal >= storeFront.minimumOrderValue)
);

const cartAlerts = viewModelAttribute(
  'cartAlerts',
  storeFront,
  cartTotal,
  strictOrderMode,

  (storeFront, cartTotal, strictOrderMode) => {
    const { minimumOrderValue, minimumOrderValueMode } = storeFront;

    if (!strictOrderMode) {
      return [];
    }

    const cartAlerts = [];

    const minimumOrderValueMessage = `Minimum order amount is $${minimumOrderValue}. Please add
        $${(minimumOrderValue - cartTotal)
          .toFixed(2)
          .replace(/[.,]00$/, '')} of product in
        order to check out.`;

    if (minimumOrderValueMode && cartTotal < minimumOrderValue)
      cartAlerts.push({ type: 'error', message: minimumOrderValueMessage });

    return cartAlerts;
  }
);

export const accessors = {
  shared,
  accountSetup: createAccountSetupViewModel,
  store,
  storeFrontId,
  cartLineItems,
  currentStoreDisplayArgs,
  storeFront,
  cartProducts: productsForOrder,
  productsForOrder,
  storeFrontCreatePurchaseOrderRequestStatus,
  cartTotal,
  order,
  isEmpty,
  orderNote,
  totalItems,
  totalLineItems,
  invalidCartProducts,
  productWithFullDetails,
  cartAlerts,
  strictOrderMode,
  categoryTotals,
  canPlaceOrder,
  ...sharedAccessors,
};

export const createViewModel = createViewModelMap(accessors);
