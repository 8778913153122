import config from 'config';
import { partnerKey } from '../headers';

export const createUri = ({ clientId = '', uri = '' } = {}) =>
  config.embedded.buildURI({
    clientId,
    uri: `/api${uri}`,
  });

export const createPreOrderUri = ({ clientId = '', retailPreOrderId = -1, uri = '' }) =>
  createUri({ clientId, uri: `/preorders/${retailPreOrderId}${uri}` });

export const createRequestHeaders = ({ menuId: menuKey = '' } = {}) => ({
  'menu-key': menuKey,
  ...partnerKey,
});
