import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';
import { noOp } from 'utils/core/funcy';

export default ({ updateItemQuantityForPreOrder = noOp, id, variants, quantityType } = {}) => {
  const onChangeCartQuantity = (newQuantity) => {
    if (newQuantity === undefined) return;
    updateItemQuantityForPreOrder({ productId: id, newQuantity });
  };

  const onDelete = createEventHandler(() => {
    updateItemQuantityForPreOrder({ productId: id, newQuantity: 0 });
  });

  const variantLabels = variants.map(({ weight, uom }) => ({
    value: weight,
    label: quantityType === 'CountBased' ? `${weight}${uom}` : undefined,
  }));

  return {
    onChangeCartQuantity,
    onDelete,
    productId: id,
    variantLabels
  };
};
