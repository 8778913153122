import { createOrderStatusKey, events } from './events';
import { createTasks as createCartTasks } from '../tasks';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { createTasks as createStoreTasks } from '../../tasks';
import { createViewModel as createViewModelImpl } from './viewModel';
import { getCurrentState } from 'utils/redux';
import { routes } from 'router';

const _dependencies = {
  createViewModel: (state = getCurrentState()) => createViewModelImpl(state),
  createRequestTasks,
  createCartTasks,
  createStoreTasks,
  routes,
};

export const createTasks = (dispatch, props = {}, dependencies = _dependencies) => {
  const {
    createViewModel: views,
    createCartTasks,
    createRequestTasks,
    routes,
    createStoreTasks,
  } = dependencies;

  const { customerInfo } = routes;

  const { placeOrder, viewCart: backToCart } = createCartTasks(dispatch, props);

  const { resume, display } = createStoreTasks(dispatch, props);

  const createOrderTasks = createRequestTasks(createOrderStatusKey, dispatch);

  const init = async () => {
    await resume();

    const { currentStoreDisplayArgs, isEmpty } = views();

    const action = isEmpty ? display : customerInfo;

    await action(currentStoreDisplayArgs);
  };

  const changeDetails = (changes = {}) => {
    dispatch(events.changedDetails(changes));
  };

  const save = async ({ form, requiresMedicalInfo }) => {
    const { preOrderType: initialPreOrderType } = views();
    const { preOrderType = initialPreOrderType, specialInstructions, ...customer } = form;

    customer.type = requiresMedicalInfo ? 'Medical' : 'Recreational';
    changeDetails({ customer, preOrderType, specialInstructions });
    await createOrderTasks.runRequest(placeOrder());
  };

  return {
    backToCart,
    save,
    init,
  };
};
