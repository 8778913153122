import { PoweredByContainer } from './styles';
import React from 'react';
import config from 'config';

export const PoweredByGrowFlow = () => (
  <div className={PoweredByContainer}>
    <span className="powered-by-text">powered by</span>
    <span className="logo-container">
      <img className="logo" width="75" alt="GrowFlow" src={config.images.logo.desktop} />
    </span>
  </div>
);

export default PoweredByGrowFlow;
