import { css } from 'emotion';

export const fullScreenSpinnerContainer = css`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9000;
  background-color: rgba(255, 255, 255, 0.8);

  .spinner {
    left: 50%;
    top: 50%;
    margin-left: -18px;
    margin-top: -18px;
    position: fixed;
  }

  .message {
    top: 50%;
    margin: 40px auto 0 auto;
    text-align: center;
    width: 100%;
    position: fixed;
  }
`;
