import {
  addToCartContainerDisplayStyle,
  gridTemplateAreasDesktop,
  gridTemplateAreasMobile,
  productCardImageOverrides,
  terpenesContainerTextAlign,
  terpenesContainerTextAlignMobile,
} from './impl';
import config from 'config';
import { css } from 'emotion';

export const listingCardLive = css`
  height: 100%;
  background: var(--gray-000);
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  padding: 20px;
  margin-top: 18px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: ${gridTemplateAreasMobile};

  &:hover {
    box-shadow: rgba(24, 41, 61, 0.1) 0px 14px 21px;
  }

  .image-name-container {
    grid-area: image-name-container;
    text-align: center;
    img {
      width: 72px;
      height: 72px;
      object-fit: scale-down;
      ${productCardImageOverrides}
    }
    p {
      text-transform: uppercase;
      color: var(--gray-900);
    }
  }

  .strain-name-container {
    grid-area: strain-name-container;
    color: var(--gray-900);
  }

  .label {
    margin-bottom: 5px;
  }

  .size-cost-container {
    grid-area: size-cost-container;
    align-self: start;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    h5 {
      color: var(--gray-600);
    }
    p {
      flex: 1 1 100%;
      color: var(--gray-900);
    }
  }

  .terpenes-container {
    grid-area: terpenes-container;
    h5 {
      color: var(--gray-600);
    }
    text-align: ${terpenesContainerTextAlignMobile};
  }

  .add-to-cart-container {
    grid-area: add-to-cart-container;
    align-self: start;
    grid-template-columns: repeat(5, 1fr);
    justify-items: start;
    display: ${addToCartContainerDisplayStyle};
    align-items: center;
    h5 {
      &.header {
        text-transform: uppercase;
        color: var(--gray-600);
      }
    }
    p {
      &.product-row {
        color: var(--gray-900);
      }
    }
    .grid-header-spacer {
      height: 4px;
      grid-column: span 1 / -1;
    }
    .grid-row-spacer {
      height: 12px;
      grid-column: span 1 / -1;
    }
    .product-row {
      input {
        text-align: center;
      }
      &.remove {
        position: relative;
        justify-self: end;
        text-align: right;
        button {
          position: absolute;
          top: -20px;
          right: 0;
          padding-right: 0;
          text-transform: uppercase;
          font-size: 12px;
          h5 {
            color: var(--purp-200);
          }
          &:hover {
            h5 {
              color: var(--purp-100);
            }
          }
        }
      }
      &.inventory-oversold-row {
        grid-column: span 2;
        padding: 5px 0;
        color: var(--red-300);
      }
    }
  }

  @media (min-width: 768px) {
    column-gap: 24px;
    row-gap: 16px;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: ${gridTemplateAreasDesktop};

    .image-name-container {
      img {
        width: 124px;
        height: 124px;
      }
    }

    .terpenes-container {
      text-align: ${terpenesContainerTextAlign};
    }
  }
`;

export const listingCardRetail = css`
  border: solid 2px blue;
  height: 100%;
  margin-top: 18px;
  background: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  padding: 24px;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 10px;

  .brand-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 20px;
  }

  .image-name-container {
    grid-area: image-name-container;
    text-align: center;
    padding: 0px;
    img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        ${productCardImageOverrides}
    }
    p {
      text-transform: uppercase;
      color: var(--gray-900);
    }
  }

  .strain-type-name-container {
    grid-area: strain-type-name-container;
    text-align: center;
    width: 80px;
    .ant-tag {
      margin-right: 0px;
    }
  }

  .strain-name-container {
    grid-area: strain-name-container;
    color: var(--gray-900);
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .terpene-label {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--gray-700);
  }

  .terpenes-container {
    grid-area: terpenes-container;
    text-align: left;
  }

  .add-to-cart-container {
    grid-area: add-to-cart-container;
    display: grid;
    justify-content: end;
    h5 {
      &.header {
        text-transform: uppercase;
        color: var(--gray-600);
      }
    }
    p {
      &.product-row {
        color: var(--gray-900);
      }
    }
    .grid-header-spacer {
      height: 4px;
      grid-column: span 1 / -1;
    }
    .grid-row-spacer {
      height: 12px;
      grid-column: span 1 / -1;
    }
    .product-row {
      input {
        text-align: center;
      }
      &.remove {
        position: relative;
        text-align: right;
        button {
          position: absolute;
          top: -20px;
          right: 0;
          padding-right: 0;
          text-transform: uppercase;
          font-size: 12px;
          h5 {
            color: var(--purp-200);
          }
          &:hover {
            h5 {
              color: var(--purp-100);
            }
          }
        }
      }
      &.inventory-oversold-row {
        grid-column: span 2;
        padding: 5px 0;
        color: var(--red-300);
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-template-rows: 3fr auto auto auto auto;
    grid-template-areas: ${gridTemplateAreasMobile};
    .strain-type-name-container {
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    row-gap: 0px;
    grid-template-columns: 110px auto auto;
    grid-template-areas: ${gridTemplateAreasDesktop};
    .add-to-cart-container {
      display: flex;
      align-items: start;
      justify-content: end;
    }
    .image-name-container {
      img {
        width: 110px;
        height: 110px;
      }
    }
    .strain-type-name-container {
      text-align: left;
    }
  }
`;

export const listingCard = config.retail ? listingCardRetail : listingCardLive;
