import { css } from 'emotion';

export const orderSummaryWrapper = css`
  @media (min-width: 1172px) {
    max-width: 550px
  }
  width: 100%;
  border: 1px solid var(--gray-400);
  background-color: var(--gray-000);
  padding: 20px;
  position: relative;

  .edit-link {
    position: absolute;
    padding: 12px;
    display: block;
    right: 9px;
    font-size: 14px;
    top: 20px;
    color: #000;
    text-decoration: underline;
  }

  h2 {
    font-size: 20px;
    margin-top: 0 !important;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: var(--gray-700);
    padding: 24px 0px;
    text-align: center;
    width: 100%;
  }

  .grand-total {
    font-weight: 600;
  }

  .order-item {
    margin: 24px 0;
    grid-template-columns: 66px auto 50px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'item-image item-name item-name'
      'item-image item-weight item-weight'
      'item-image item-qty item-price';
    display: grid;
    grid-column-gap: 12px;

    img {
      grid-area: item-image;
      width: 66px;
      height: 66px;
      object-fit: cover;
      border: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    .item-name {
      grid-area: item-name;
      line-height: 22px;
      font-size: 14px;
      color: var(--gray-900);
      font-weight: 600;
    }

    .item-qty {
      grid-area: item-qty;
    }

    .item-weight,
    .item-price,
    .item-qty {
      color: var(--gray-700);
      font-size: 14px;
      line-height: 22px;
    }

    .item-price {
      grid-area: item-price;
      text-align: right;
    }

    .discounted {
      color: var(--green-300);
      font-size: 14px;
    }
  }

  .order-breakdown {
    .title,
    .value {
      margin-bottom: 13px;
    }
    .value {
      text-align: right;
    }
  }

  .place-order-button {
    background-color: #000;
    margin-top: 15px;
    border-color: #000;
    margin-bottom: 10px;
    padding: 12px 0 34px 0;
    text-transform: uppercase;
      &:hover, &:active {
        background-color: #000;
        border-color: #000;
      }
  }
`;
