export const addToCartContainerDisplayStyle = 'grid';

export const productCardImageOverrides = `
  cursor: default;
`;

export const gridTemplateAreasDesktop = `
  'image-name-container strain-name-container add-to-cart-container'
  'image-name-container terpenes-container add-to-cart-container'
  'image-name-container strain-type-name-container add-to-cart-container'
`;

export const gridTemplateAreasMobile = `
  'image-name-container'
  'strain-name-container'
  'terpenes-container'
  'strain-type-name-container'
  'add-to-cart-container'
`;

export const terpenesContainerTextAlign = 'right';
export const terpenesContainerTextAlignMobile = 'left';
