import { createUri as createBaseUri, createRequestHeaders } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '' } = {}) =>
  createBaseUri({ clientId, uri: '/preorders/create' });

const createRequestPayload = ({ productId, quantity: qty }) => ({
  orderItems: [
    {
      productId,
      qty,
    },
  ],
});

export default async ({ clientId = '', menuId = '', productId, quantity } = {}) => {
  const r = await Axios.post(
    createUri({ clientId }),
    createRequestPayload({ productId, quantity }),
    {
      headers: createRequestHeaders({ menuId }),
    }
  );

  return r.data;
};
