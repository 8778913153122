import {
  cart,
  cartForStoreFront,
  createViewModel as createSharedStoreViewModel,
  currentStoreDisplayArgs,
  hasStoreFront,
  parentCategoryIds,
  productListings,
  productWithFullDetails,
  productsList,
  quantityForProduct,
  sortOrder,
  store,
  storeFront,
  storeFrontId,
  textQuantityForProduct,
} from '../shared/viewModel';
import { comparisons, sort } from '@developwithpassion/comparers_js';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { hasActiveFilters, productFilter } from '../components/Filter/ux/viewModel';
import { createViewModel as createAccountSetupViews } from '../../account-setup/viewModel';
import { createViewModel as createProfileViewModel } from 'components/Profile/viewModel';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getCurrentState } from 'utils/redux';
import { identity } from 'utils/core/funcy';
import { accessors as implAccessors } from './impl';
import { isNonEmptyMap } from 'utils/matchers';
import { isValidStoreId } from '../../browse-stores/viewModel';
import { data as localStorageDataImpl } from 'utils/localStorage';
import lunr from 'lunr';
import { reducerForUniqueMap } from '../utils';
import { sortOrderList } from '../shared/sortOrders';
export {
  clearProductListings,
  storeFrontId,
  setProductListings,
  currentStoreDisplayArgs,
} from '../shared/viewModel';
import { criteria as where } from '@developwithpassion/match_js';

const view = createViewModelAttributeBuilder(fileAbsolute);

const dependencies = {
  localStorageData: localStorageDataImpl,
};

export const shared = view(
  'Store - Creating Shared Store Views',
  ({ store }) => store,
  () => createSharedStoreViewModel(getCurrentState())
);

const initialized = view('initialized', store, ({ initialized }) => initialized);

const searchTerm = view(
  'searchTerm',
  ({ store: { searchTerm } }) => searchTerm,
  identity
);

const pageNumber = view(
  'pageNumber',
  ({ store: { pageNumber } }) => pageNumber,
  identity
);

const pageSize = view('pageSize', ({ store: { pageSize } }) => pageSize, identity);

const localStorageValidators = {
  hasCart: where({
    cart: isNonEmptyMap,
  }),
};

const hasCartInLocalStorage = () =>
  localStorageValidators.hasCart(dependencies.localStorageData());

const removeEmptyLineItems = storeCart =>
  Object.keys(storeCart).reduce((acc, key) => {
    const lineItem = storeCart[key];
    if (!lineItem.shouldBeRemoved) acc[key] = lineItem;
    return acc;
  }, {});

export const cartWithoutEmptyLineItems = cartToClear =>
  Object.keys(cartToClear).reduce(
    (acc, key) => ({ ...acc, [key]: removeEmptyLineItems(cartToClear[key]) }),
    {}
  );

const cartForLocalStorage = view('cartForLocalStorage', cart, cartWithoutEmptyLineItems);

const hasCartForStoreFront = view(
  'cartForStoreFront',
  cartForStoreFront,
  cartForStoreFront => isNonEmptyMap(cartForStoreFront)
);

const getStoreProductsStatus = view(
  'getStoreProductsStatus',
  ({ store: { getStoreProductsStatus } }) => getStoreProductsStatus,
  val => createRequestStatusViews(val)
);

const storeProductsRequestIsCompleted = view(
  'storeProductsRequestIsCompleted',
  getStoreProductsStatus,
  ({ completed }) => completed
);

const filteredProductsMap = view(
  'filteredProductsMap',
  productFilter,
  productsList,
  (filter, products) =>
    products.filter(filter).reduce(
      reducerForUniqueMap('id', x => x),
      {}
    )
);

const filteredProductsList = view(
  'filteredProductsList',
  filteredProductsMap,
  Object.values
);

const productsSearchIndex = view('productsSearchIndex', filteredProductsList, products =>
  lunr(function () {
    this.field('name');
    this.field('strainTypeName');
    this.field('strainName');
    this.field('productBrandName');
    this.field('price');

    products.forEach(val => {
      this.add(val);
    });
  })
);

const filteredCategoryMap = view('filteredCategoryMap', filteredProductsList, val =>
  val.reduce(
    reducerForUniqueMap(
      'productCategoryParentId',
      ({ productCategoryParentId, productCategoryParentName }) => ({
        id: productCategoryParentId,
        name: productCategoryParentName,
        key: productCategoryParentId,
      })
    ),
    {}
  )
);

const listingsByParentCategory = view(
  'listingsByFilteredCategories',
  productListings,
  listings =>
    listings.reduce((acc, item) => {
      const items = acc[item.parentCategoryId] || (acc[item.parentCategoryId] = []);
      items.push(item);
      return acc;
    }, {})
);

const createListingMapper = filteredProductsMap => ({
  id: listingId,
  products,
  ...rest
}) => {
  products = products.map(({ id, ...rest }) => ({
    id,
    listingId,
    ...rest,
    includedInFilter: !!filteredProductsMap[id],
  }));

  return {
    id: listingId,
    ...rest,
    includedInFilter:
      products.filter(({ includedInFilter }) => includedInFilter).length > 0,
    products,
  };
};

const _filteredListings = (
  listingsByParentCategory,
  filteredCategoryMap,
  filteredProductsMap,
  sortOrder
) =>
  Object.entries(listingsByParentCategory)
    .filter(([parentId]) => filteredCategoryMap[parentId])
    .map(([parentId, listings]) => ({
      category: filteredCategoryMap[parentId],
      listings: listings
        .map(createListingMapper(filteredProductsMap))
        .filter(({ includedInFilter }) => includedInFilter)
        .sort(sortOrder.listingComparer),
    }));

const filteredListings = view(
  'filteredListings',
  listingsByParentCategory,
  filteredCategoryMap,
  filteredProductsMap,
  sortOrder,
  _filteredListings
);

const searchTerms = view('searchTerms', searchTerm, val =>
  val
    .split(' ')
    .map(val => val.trim())
    .map(val => `*${val}*`)
    .join(' ')
);

const applicableProductsList = view(
  'applicableProductsList',
  filteredProductsMap,
  productsSearchIndex,
  searchTerms,
  (filteredProductsMap, productsSearchIndex, searchTerms) =>
    productsSearchIndex.search(searchTerms).map(({ ref }) => filteredProductsMap[ref])
);

const resultsCount = view('resultsCount', applicableProductsList, val => val.length);

const applicableListingsMap = view(
  'applicableListingsMap',
  applicableProductsList,
  productsList =>
    productsList.reduce(
      reducerForUniqueMap('listingId', () => true),
      {}
    )
);

const _applicableListings = (searchResults, filteredListings, parentCategoryIds) =>
  filteredListings
    .sort(
      sort.using_accessor(
        ({ category: { id } }) => id,
        comparisons.fixed(parentCategoryIds)
      )
    )
    .reduce((acc, { listings }) => {
      listings = listings.filter(({ id }) => searchResults[id]);

      return listings.length > 0 ? acc.concat(listings) : acc;
    }, []);

const applicableListings = view(
  'applicableListings',
  applicableListingsMap,
  filteredListings,
  parentCategoryIds,
  _applicableListings
);

const listingCount = view(
  'listingCount',
  applicableListings,
  listings => listings.length
);

const pageStartIndex = view(
  'pageStartIndex',
  pageNumber,
  pageSize,
  (number, size) => (number - 1) * size
);

const pageEndIndex = view(
  'pageEndIndex',
  pageStartIndex,
  pageSize,
  (startIndex, pageSize) => startIndex + pageSize
);

const pageListings = view(
  'pageListings',
  applicableListings,
  pageStartIndex,
  pageEndIndex,
  (listings, startIndex, endIndex) => listings.slice(startIndex, endIndex)
);

const categories = view(
  'categories',
  applicableListings,
  parentCategoryIds,
  (val, parentCategoryIds) =>
    val.map(({ category }) => category).sort(sort.by_fixed('id', parentCategoryIds))
);

export const accessors = {
  pageNumber,
  pageSize,
  listingCount,
  sortOrderList: () => sortOrderList,
  profile: createProfileViewModel,
  accountSetup: createAccountSetupViews,
  hasCartInLocalStorage,
  hasCartForStoreFront,
  initialized,
  hasStoreFront,
  filteredProductsList,
  filteredProductsMap,
  resultsCount,
  categories,
  filteredListings,
  applicableListings,
  pageListings,
  productListings,
  cart,
  cartForLocalStorage,
  storeFront,
  storeFrontId,
  getStoreProductsStatus,
  isValidStoreId,
  productsAreLoaded: storeProductsRequestIsCompleted,
  productWithFullDetails,
  productsSearchIndex,
  sortOrder,
  hasActiveFilters,
  quantityForProduct,
  textQuantityForProduct,
  currentStoreDisplayArgs,
  ...implAccessors,
};

export const createViewModel = createViewModelMap(accessors);

export const __test__ = {
  _applicableListings,
  _filteredListings,
  shared,
  quantityForProduct,
  hasCartInLocalStorage,
  dependencies,
};
