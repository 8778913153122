import { css } from 'emotion';

export const buttonGroupStyles = (displayError) => css`
  width: 100%;
  display: grid;

  .sold-as-each {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .ant-btn {
      width: 100%;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--gray-400);
  }
  ${displayError ? `
  .ant-select:not(.ant-select-disabled) .ant-select-selector {
    border-color: #cf1322;
  }
  .ant-select-selector {
    border: solid 1px #cf1322;
    box-shadow: 0px 0px 4px rgba(245, 34, 45, 0.5);
  }
` : null}

  .price-label {
    grid-area: price-label;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--gray-900);
    display: flex;
    align-items: flex-end;
  }

  .size-select {
    grid-area: size-select;
    width: 100%;
  }

  .add-button {
    grid-area: add-button;
    width: 100%;
    display: flex;
    flex-direction: column;
    .ant-btn {
      margin-top: 8px;
      width: 100%;
    }
  }

  .error-message {
    font-size: 12px;
    line-height: 20px;
    color: #cf1322;
    width: 100%;
    text-align: right;
    margin: 8px 0px 2px 0px;
  }

  .button-container {
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  grid-template-rows: auto auto auto;
  grid-template-areas:
    'price-label'
    'size-select'
    'add-button';

  @media (min-width: 428px) {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'price-label size-select'
    'add-button add-button';
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    max-width: 252px;
    .sold-as-each {
      .ant-btn {
        max-width: 252px;
      }
    }
    .error-message {
      width: 252px;
    }
    .size-select {
      height: 32px;
      width: 252px;
      margin: 8px 0px 0px 0px;
    }
    .add-button {
      width: 252px;
      display: block;
      .ant-btn {
        width: 252px;
      }
      .error-message {
        text-align: left;
      }
    }
  }
`;

export const cartOverlayStyles = css`
  width: 280px;
  padding: 8px;
  p {
    color: #57579;
  }
  .justify-between {
    color: #57579;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 23px 0px;
  }
  .popover-buttons {
    .ant-btn {
      width: 118px;
    }
  }
`;

export const popoverTitleStyles = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  .anticon svg {
    width: 21px;
    height: 21px;
    color: #389E0D;
  }
`;
