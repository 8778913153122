import { createPreOrderUri, createRequestHeaders } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '', retailPreOrderId = -1 } = {}) =>
  createPreOrderUri({ clientId, retailPreOrderId, uri: '/add-item' });

const createRequestPayload = ({ productId, quantity: qty }) => ({
  orderItems: [
    {
      productId,
      qty,
    },
  ],
});

export default async ({
  clientId = '',
  menuId = '',
  retailPreOrderId = -1,
  productId,
  quantity,
} = {}) => {
  const r = await Axios.put(
    createUri({ clientId, retailPreOrderId }),
    createRequestPayload({ productId, quantity }),
    {
      headers: createRequestHeaders({ menuId }),
    }
  );

  return r.data;
};
