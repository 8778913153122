import { component, hocWithMappings as hoc } from 'utils/views';
import { compose, identity } from 'utils/core/funcy';
import Template from './template';
import controllerBackToCart from './controller.backToCart';
import controllerCart from './controller.cart';
import controllerCheckout from './controller.checkout';
import controllerOrderComplete from './controller.orderComplete';
import { createViewModel } from './viewModel';
export * from './controller.orderComplete';

const OrderComponentWithBackToCartController = hoc(
  'Order Summary Component - Controller - Back To Cart',
  controllerBackToCart
);

const OrderComponentWithCartController = hoc(
  'Order Summary Component - Controller - Cart',
  controllerCart
);

const OrderComponentWithPlaceOrderController = hoc(
  'Order Summary Component - Controller - Place Order',
  controllerCheckout
);

const OrderCompleteController = hoc(
  'Order Summary Component - Order Complete UX',
  controllerOrderComplete
);

const createSummaryView = (extraMixin, viewModel = createViewModel) =>
  component({
    Template: compose(OrderComponentWithBackToCartController, extraMixin)(Template),
    createViewModel: viewModel,
  });

export const OrderSummaryNonCheckout = createSummaryView(
  OrderComponentWithCartController
);

export const OrderSummaryPlaceOrder = createSummaryView(
  OrderComponentWithPlaceOrderController
);

export const OrderSummaryOrderConfirmation = createSummaryView(
  OrderCompleteController,
  identity
);

export default OrderSummaryNonCheckout;
