import { number, object, simple } from './mappers';
import { noOp } from 'utils/core/funcy';

// Mapping of local storage keys to the mappers that will map the data back and forth to local storage.
// Any key value pair that you want to store in local storage needs to be defined in this map. Any attempt
// to update data in local storage using keys that are not defined in this map will fail.
const storageKeysAndMappers = {
  state: simple,
  contactInfo: object,
  selectedVendorId: number.withErrorDefault(0),
  cart: object,
  retailPreOrderId: simple,
};

const invalidKeys = keys =>
  keys.reduce((acc, val) => (storageKeysAndMappers[val] ? acc : acc.concat(val)), []);

const ensureAllKeysAreValid = keys => {
  const allInvalidKeys = invalidKeys(keys);

  if (allInvalidKeys.length === 0) return;

  throw new Error(
    `Storage mappers need to be defined for attributes: [${allInvalidKeys.join(',')}]`
  );
};

// Retrieves the value for a particular key from local storage and then maps it using the mapper defined in
// the storageKeysAndMappers map
const getData = key =>
  storageKeysAndMappers[key].mapFromStorage(window.localStorage.getItem(key));

// Retrieves all of the key value pairs stored in local storage as an object with the necessary mappings having
// been performed
export const data = () =>
  Object.entries(storageKeysAndMappers).reduce(
    (acc, [key]) => Object.assign(acc, { [key]: getData(key) }),
    {}
  );

// Updates the data in local storage using a callback of the format
// Map -> Map
// where the callback can take advantage of any of the data that has already been stored in local storage
// ex: update(({ contactInfo }) => ({ contactInfo: { ...contactInfo, firstName: `${contactInfo.firstName}.changed`}}))
export const update = (getChanges = noOp) => {
  const existingData = data();
  const changes = getChanges(existingData);

  ensureAllKeysAreValid(Object.keys(changes));

  Object.entries(changes).forEach(([key, value]) => {
    const mapper = storageKeysAndMappers[key];
    window.localStorage.setItem(key, mapper.mapToStorage(value));
  });
};

export const remove = objectWithKeys =>
  Object.keys(objectWithKeys).forEach(key => {
    window.localStorage.removeItem(key);
  });
