export { default as CartSummary } from './components/Summary';
import './reducers';
import {
  hocWithMappings as hoc,
  page,
  pageWithAuth,
  withBreakpointDetection,
  withSEO,
} from 'utils/views';
import Template from './template';
import { compose } from 'utils/core/funcy';
import controllerAnalytics from './controller.analytics';
import controllerEventHandlers from './controller.eventHandlers';
import controllerLaunchDarkly from 'utils/controllers/launchDarkly';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Main as withLayout } from 'layouts';

const TemplateWithAnalytics = hoc('Cart With Analytics', controllerAnalytics);
const TemplateWithEventHandlers = hoc(
  'Cart With Event Handlers',
  controllerEventHandlers
);
const TemplateWithLaunchDarkly = hoc(
  'Cart - Controller - Launch darkly flags',
  controllerLaunchDarkly
);
const FullTemplate = compose(
  withBreakpointDetection(),
  TemplateWithAnalytics,
  TemplateWithEventHandlers,
  TemplateWithLaunchDarkly
)(Template);

export default withSEO({
  title: 'Cart',
})(
  pageWithAuth({
    Template: FullTemplate,
    withLayout,
    createTasks,
    createViewModel,
  })
);

export const RetailCart = withSEO({
  title: 'Cart',
})(
  page({
    Template: FullTemplate,
    createTasks,
    createViewModel,
  })
);
