import { createTasks as createCartTasks } from '../../tasks';
import { noOp } from 'utils/core/funcy';

export const createTasks = dispatch => {
  const cartTasks = createCartTasks(dispatch);

  const init = noOp;

  return {
    ...cartTasks,
    init,
  };
};
