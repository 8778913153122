import { anything, regex } from '@developwithpassion/matchers_js';
import {
  createViewModel as createSharedViewModel,
  storeFronts,
} from './shared/viewModel';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { identity, prop } from 'utils/core/funcy';
import { sort as createComparer } from '@developwithpassion/comparers_js';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { createViewModel as createStoreViewModel } from './storeFront/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getCurrentState } from 'utils/redux';
import { criteria as where } from '@developwithpassion/match_js';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const browseStores = viewModelAttribute(prop('browseStores'), identity);
const propView = key => viewModelAttribute(browseStores, prop(key));
const unlocked = propView('unlocked');
const filter = propView('filter');
const filterIsActive = viewModelAttribute(filter, val => val.trim().length > 0);

/* Creates a comparer function that will compare 2 objects using the 'accessor' provided in
 * the first argument to do value resolution
 * In this case a comparer function is being created that will use the name attribute on a nested storeFront object
 */
const storeComparer = createComparer.using_accessor(({ storeFront: { name } }) =>
  name.toLowerCase()
);

const storeFrontsCompleteList = viewModelAttribute(storeFronts, storeFronts => {
  const state = getCurrentState();
  return Object.values(storeFronts).map(({ id }) => createStoreViewModel(state, { id }));
});

const storeFrontsList = viewModelAttribute(
  storeFrontsCompleteList,
  unlocked,
  (storeFronts, unlocked) =>
    storeFronts.filter(({ isUnlocked }) => isUnlocked === unlocked).sort(storeComparer)
);

const lockedStoreFrontsCount = viewModelAttribute(
  storeFrontsCompleteList,
  items => items.filter(item => item.isLocked).length
);

const unlockedStoreFronts = viewModelAttribute(storeFrontsCompleteList, items =>
  items.filter(item => item.isUnlocked)
);

export const isValidStoreId = viewModelAttribute(
  unlockedStoreFronts,
  items => storeFrontId => items.filter(({ id }) => id === storeFrontId).length > 0
);

const unlockedStoreFrontsCount = viewModelAttribute(
  unlockedStoreFronts,
  items => items.length
);

const getStoreFrontsRequestStatus = viewModelAttribute(
  browseStores,
  ({ getStoreFrontsRequestStatus }) =>
    createRequestStatusViews(getStoreFrontsRequestStatus)
);

const storeCriteria = viewModelAttribute(
  filterIsActive,
  filter,
  (filterIsActive, filter) =>
    filterIsActive
      ? where({
          storeFront: {
            name: regex(new RegExp(filter, 'i')),
          },
        })
      : anything
);

const filteredStoreFronts = viewModelAttribute(
  storeFrontsList,
  storeCriteria,
  (storeFrontsList, storeCriteria) => storeFrontsList.filter(storeCriteria)
);

const hasNoResultsDueToFiltering = viewModelAttribute(
  storeFrontsList,
  filteredStoreFronts,
  filterIsActive,
  (storeFrontsList, filteredStoreFronts, filterIsActive) =>
    storeFrontsList.length > 0 && filterIsActive && filteredStoreFronts.length === 0
);

const accessors = {
  shared: createSharedViewModel,
  ownState: browseStores,
  filterIsActive,
  unlockedStoreFrontsCount,
  lockedStoreFrontsCount,
  hasNoResultsDueToFiltering,
  getStoreFrontsRequestStatus,
  storeFrontsList: filteredStoreFronts,
  noMatchingStoresBasedOnFilter: () => {},
};

export const createViewModel = createViewModelMap(accessors);

export const __test__ = {
  storeComparer,
};
