import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { identity, prop } from 'utils/core/funcy';
import { accessors as baseAccessors } from '../viewModel';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import usStates from './usStates';

const createOrderType = (id, description) => ({ id, description });

const createState = ({ name, code }) => ({ id: code, name, value: code });

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const embeddedOrder = viewModelAttribute(
  'embeddedOrder',
  ({ embeddedOrder }) => embeddedOrder,
  identity
);

export const orderTypes = {
  pickup: createOrderType('Pickup', 'Pickup'),
  delivery: createOrderType('Delivery', 'Delivery'),
};

export const preOrderType = viewModelAttribute(
  'preOrderType',
  embeddedOrder,
  prop('preOrderType')
);

export const specialInstructions = viewModelAttribute(
  'specialInstructions',
  embeddedOrder,
  prop('specialInstructions')
);

export const isMedicalPatient = viewModelAttribute(
  'isMedicalPatient',
  embeddedOrder,
  prop('isMedicalPatient')
);

export const customer = viewModelAttribute('customer', embeddedOrder, prop('customer'));

export const createOrderStatus = viewModelAttribute(
  'createOrderStatus',
  ({ embeddedOrder: { createOrderStatus } }) => createOrderStatus,
  createRequestStatusViews
);

export const deliveryAddress = viewModelAttribute(
  'deliveryAddress',
  embeddedOrder,
  prop('deliveryAddress')
);

const states = usStates.map(createState);

const form = viewModelAttribute(
  'orderForm',
  preOrderType,
  customer,
  deliveryAddress,
  isMedicalPatient,
  specialInstructions,
  (preOrderType, customer, deliveryAddress, isMedicalPatient, specialInstructions) => ({
    type: preOrderType,
    ...customer,
    ...deliveryAddress,
    isMedicalPatient,
    specialInstructions,
  })
);

const preOrderData = viewModelAttribute(
  'preOrderData',
  preOrderType,
  customer,
  deliveryAddress,
  specialInstructions,
  (preOrderType, customer, deliveryAddress, specialInstructions) => ({
    preOrderType,
    customer: {
      ...customer,
      dob: customer.dob ? customer.dob.format('YYYY-MM-DD') : null,
    },
    deliveryAddress,
    specialInstructions,
  })
);

const accessors = {
  preOrderType,
  customer,
  createOrderStatus,
  form,
  orderTypes: () => orderTypes,
  states: () => states,
  preOrderData,
  ...baseAccessors,
};

export const createViewModel = createViewModelMap(accessors);
