import { equal_to, not } from '@developwithpassion/matchers_js';
import { identity, prop } from 'utils/core/funcy';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { accessors as sharedAccessors } from '../../../shared/viewModel';

const view = createViewModelAttributeBuilder(fileAbsolute);

const retailPreOrderId = view(
  'retailPreOrderId',
  ({ store: { retailPreOrderId } }) => retailPreOrderId,
  identity
);

const preOrderHasBeenCreated = view(
  'retailPreOrderId',
  retailPreOrderId,
  not(equal_to(-1))
);

const store = view('supportsPreOrders', ({ store }) => store, identity);

const addProductToCartStatus = view(
  'addProductToCartStatus',
  ({ store: { addProductToCartStatus } }) => addProductToCartStatus,
  createRequestStatusViews
);

const updateItemQuantityForPreOrderStatus = view(
  'updateItemQuantityForPreOrderStatus',
  ({ store: { updateItemQuantityForPreOrderStatus } }) => updateItemQuantityForPreOrderStatus,
  createRequestStatusViews
);

const supportsPreOrders = view('supportsPreOrders', store, prop('supportsPreOrders'));
const supportsDelivery = view('supportsDelivery', store, prop('supportsDelivery'));
const storeType = view('storeType', store, prop('storeType'));
const storeName = view('storeName', store, prop('storeName'));
const storeLocation = view('storeLocation', store, prop('storeLocation'));
const normalizedStoreType = view('normalizedStoreType', storeType, val =>
  val.toLowerCase()
);
const isRecreationalStore = view(
  'isRecreationalStore',
  normalizedStoreType,
  equal_to('recreational')
);
const isMedicalOnlyStore = view(
  'isMedicalOnlyStore',
  normalizedStoreType,
  equal_to('medical')
);
const isMedRecStore = view('isMedRecStore', normalizedStoreType, equal_to('medrec'));
const isMedicalStore = view(
  'isMedicalStore',
  isMedicalOnlyStore,
  isMedRecStore,
  (isMedicalOnlyStore, isMedRecStore) => isMedicalOnlyStore || isMedRecStore
);

const storeDetails = view(
  'storeDetails',
  storeType,
  storeName,
  storeLocation,
  isMedicalStore,
  isRecreationalStore,
  isMedRecStore,
  (
    storeType,
    storeName,
    storeLocation,
    isMedicalStore,
    isRecreationalStore,
    isMedRecStore
  ) => ({
    name: storeName,
    type: storeType,
    supportsPreOrders,
    supportsDelivery,
    location: storeLocation,
    isMedicalStore,
    isRecreationalStore,
    isMedRecStore,
  })
);

const lastVariantAdded = view('lastVariantAdded', store, store => store.lastVariantAdded);

export const accessors = {
  addProductToCartStatus,
  updateItemQuantityForPreOrderStatus,
  preOrderHasBeenCreated,
  retailPreOrderId,
  supportsDelivery,
  supportsPreOrders,
  storeType,
  storeName,
  storeLocation,
  storeDetails,
  ...sharedAccessors,
  isRecreationalStore,
  isMedicalOnlyStore,
  isMedRecStore,
  isMedicalStore,
  lastVariantAdded,
};
