import { createPreOrderUri, createRequestHeaders } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '', retailPreOrderId = -1 } = {}) =>
  createPreOrderUri({ clientId, retailPreOrderId });

export default async ({ clientId = '', menuId = '', retailPreOrderId = -1 } = {}) => {
  const r = await Axios.get(createUri({ clientId, retailPreOrderId }), {
    headers: createRequestHeaders({ menuId }),
  });

  return r.data.order;
};
