import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const requiredString = (message = 'Required') => yup.string().required(message);

const mapFormForUI = form => ({
  ...form,
  dob: form.dob ? moment(form.dob) : null,
});

const createDateChangeHandler = ({ setFieldValue, fieldName }) => date => {
  setFieldValue(fieldName, date);
};

const createStateChangeHandler = setFieldValue => (_, { value }) => {
  setFieldValue('state', value);
};

const customerSchema = ({ requiresMedicalAnswer = false } = {}) => {
  const schema = {
    firstName: requiredString().min(2, 'Too short'),
    lastName: requiredString().min(2, 'Too short'),
    email: yup.string().email('Invalid email').required('Required'),
    phone: yup.string(),
    dob: requiredString().nullable(),
  };

  if (!requiresMedicalAnswer) return schema;

  schema.isMedicalPatient = yup.boolean().required('Required').nullable();

  return schema;
};

const deliverySchema = ({ isDeliverySelected }) =>
  isDeliverySelected
    ? {
        streetAddress1: requiredString(),
        city: requiredString(),
        state: requiredString(),
        zipcode: requiredString(),
      }
    : {};

const medicalInfoSchema = ({ requiresMedicalInfo }) =>
  requiresMedicalInfo
    ? {
        medicalLicenseNumber: requiredString().nullable(),
        medicalLicenseExpires: requiredString().nullable(),
      }
    : {};

const validationSchema = ({ isDeliverySelected, isMedRecStore, requiresMedicalInfo }) =>
  yup.object({
    ...customerSchema({ requiresMedicalAnswer: isMedRecStore }),
    ...deliverySchema({ isDeliverySelected }),
    ...medicalInfoSchema({ requiresMedicalInfo }),
  });

const withErrorHandling = ({ errorHandler, target }) => async (...args) => {
  try {
    return await target(...args);
  } catch (e) {
    errorHandler(e);
  }
};

const fieldsToFocus = [
  'dob',
  'email',
  'firstName',
  'isMedicalPatient',
  'lastName',
  'medicalLicenseExpires',
  'medicalLicenseNumber',
  'phoneNumber',
  'streetAddress1',
  'city',
  'state',
  'zipcode',
];

const ux = ({
  form = {},
  supportsDelivery = true,
  orderTypes = {},
  states = [],
  save,
  isMedicalOnlyStore = false,
  isMedRecStore = false,
} = {}) => {
  let dataForm = null;

  const [isDeliverySelected, setIsDeliverySelected] = useState(false);

  const [makingMedicalPurchase, setMakingMedicalPurchase] = useState(false);

  const [defaultOrderType, setDefaultOrderType] = useState(orderTypes.pickup.id);

  const [errorMessage, setErrorMessage] = useState('');

  const inputRefs = fieldsToFocus.reduce(
    (acc, val) => ({ ...acc, [val]: useRef(null) }),
    {}
  );

  const createMedicalPatientStatusChangedHandler = handleChange => (...args) => {
    handleChange(...args);

    const [
      {
        target: { value },
      },
    ] = args;

    setMakingMedicalPurchase(value);
  };

  const requiresMedicalInfo = isMedicalOnlyStore || makingMedicalPurchase;

  useEffect(() => {
    inputRefs.firstName.current.focus();
    dataForm.resetForm();
  }, []);

  const focusFirstInputError = errors => {
    const [firstErrorField] = Object.keys(errors);
    const ref = inputRefs[firstErrorField];
    if (ref && ref.current) ref.current.focus();
  };

  const onSave = async () => {
    await dataForm.submitForm();

    if (!dataForm.isValid) focusFirstInputError(dataForm.errors);
  };

  const onFormInitialized = formProps => {
    dataForm = formProps;
  };

  const createOrderTypeSelectedHandler = orderType => () => {
    setIsDeliverySelected(orderType.id === orderTypes.delivery.id);
    setDefaultOrderType(orderType.id);
  };

  const orderTypesForUI = Object.values(orderTypes).map(data => ({
    ...data,
    id: `orderType-${data.id}`,
    onClick: createOrderTypeSelectedHandler(data),
    isDisabled: data.id === orderTypes.delivery.id && !supportsDelivery,
    value: data.id,
  }));

  const statesForUI = states.map(({ id, ...rest }) => ({
    id,
    ...rest,
    text: id,
    key: id,
  }));

  const formForUI = mapFormForUI(form);

  const formSchema = validationSchema({
    isDeliverySelected,
    isMedRecStore,
    requiresMedicalInfo,
  });

  const displayResponseError = ({
    response: {
      data: { error },
    },
  }) => {
    setErrorMessage(error);
  };

  const onFormSubmit = async form => {
    setErrorMessage('');
    await save({ form, requiresMedicalInfo });
  };

  const submit = withErrorHandling({
    errorHandler: displayResponseError,
    target: onFormSubmit,
  });

  const errors = {
    hasErrorToDisplay: errorMessage.length > 0,
    message: errorMessage,
  };

  return {
    requiresMedicalInfo,
    onSave,
    onFormInitialized,
    inputRefs,
    onFormSubmit: submit,
    orderTypes: orderTypesForUI,
    isDeliverySelected,
    defaultOrderType,
    form: formForUI,
    formSchema,
    states: statesForUI,
    errors,
    createMedicalPatientStatusChangedHandler,
    createStateChangeHandler,
    createDateChangeHandler,
  };
};

export default ux;
