import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { pipe, prop } from 'utils/core/funcy';
import { accessors as baseAccessors } from '../viewModel';
import { centsToDollars } from 'utils/ui/mappers';
import { fileAbsolute } from 'paths.macro';

const { currentPreOrder } = baseAccessors;

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const orderItemMapper = ({
  grossPrice,
  netPrice,
  originalPrice,
  priceBeforeDiscounts,
  price,
  ...rest
}) => ({
  ...rest,
  grossPrice: centsToDollars(grossPrice),
  netPrice: centsToDollars(netPrice),
  originalPrice: centsToDollars(originalPrice),
  priceBeforeDiscounts: centsToDollars(priceBeforeDiscounts),
  price: centsToDollars(price),
});

const orderItems = viewModelAttribute(
  'orderItems',
  currentPreOrder,
  pipe(prop('items'), items => items.map(orderItemMapper))
);

const orderSubtotal = viewModelAttribute(
  'orderSubtotal',
  currentPreOrder,
  pipe(prop('subtotal'), centsToDollars)
);

const orderTaxTotal = viewModelAttribute(
  'orderTaxTotal',
  currentPreOrder,
  pipe(prop('taxes'), centsToDollars)
);

const orderGrandTotal = viewModelAttribute(
  'orderGrandTotal',
  currentPreOrder,
  pipe(prop('total'), centsToDollars)
);

const taxesIncluded = viewModelAttribute(
  'taxesIncluded',
  currentPreOrder,
  prop('inclusiveTaxes')
);

const orderDiscountsTotal = viewModelAttribute(
  'discounts',
  currentPreOrder,
  pipe(prop('discounts'), centsToDollars)
);

const groupedOrderItems = viewModelAttribute(
  'groupedOrderItems',
  orderItems,
  items => items
);

export const accessors = {
  ...baseAccessors,
  orderItems,
  orderSubtotal,
  orderTaxTotal,
  orderGrandTotal,
  taxesIncluded,
  orderDiscountsTotal,
  groupedOrderItems,
};

export const createViewModel = createViewModelMap(accessors);
