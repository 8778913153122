import * as styles from './styles';
import { Button, Col, Divider, Row } from 'antd';
import { toCurrency, toUomAbbreviation } from 'utils/ui/mappers';
import Image from 'components/Image';
import React from 'react';
import config from 'config';
import { noOp } from 'utils/core/funcy';

const orderItemTemplate = ({
  imageUrl,
  price,
  quantityInCart,
  unitWeight,
  unitWeightUOM,
  strain,
  name,
} = {}) => {

  const displayName = strain ? `${strain.name} ${name}` : name;

  return (
    <div className="order-item">
      <Image
        src={imageUrl || config.images.fallback}
        alt={`image of ${displayName}`}
        fallback={config.images.fallback}
      />
      <div className="item-name">{displayName}</div>
      <div className="item-weight">
        {unitWeight}
        {toUomAbbreviation(unitWeightUOM)}
      </div>
      <div className="item-qty">
        Qty: {quantityInCart} @ {toCurrency(price)}
      </div>
      <div className="item-price">{toCurrency(quantityInCart * price)}</div>
    </div>
  );
};

const orderBreakdownTemplate = ({
  orderSubtotal,
  orderDiscountsTotal,
  taxesIncluded,
  orderTaxTotal,
  orderGrandTotal,
}) => (
  <Row className="order-breakdown">
    <Col span={12}>
      <div className="title">Subtotal</div>
    </Col>
    <Col span={12}>
      <div className="value">{toCurrency(orderSubtotal)}</div>
    </Col>
    {orderDiscountsTotal > 0 && (
      <>
        <Col span={12}>
          <div className="title">Discounts</div>
        </Col>
        <Col span={12}>
          <div className="value">-{toCurrency(orderDiscountsTotal)}</div>
        </Col>
      </>
    )}
    <Col span={12}>
      <div className="title">{`Est. Tax ${taxesIncluded ? '(included)' : ''}`}</div>
    </Col>
    <Col span={12}>
      <div className="value">{toCurrency(orderTaxTotal)}</div>
    </Col>
    <Col span={12}>
      <div className="title grand-total">
        <strong>Total</strong>
      </div>
    </Col>
    <Col span={12}>
      <div className="value grand-total">
        <strong>{toCurrency(orderGrandTotal)}</strong>
      </div>
    </Col>
    <p>Other discounts may be taken at register</p>
  </Row>
);

const EmbeddedOrderSummary = ({
  onEditOrder = noOp,
  groupedCartItems = [],
  orderSubtotal = 0,
  orderTaxTotal = 0,
  orderDiscountsTotal = 0,
  orderGrandTotal = 0,
  taxesIncluded = false,
  isConfirmation = false,
  isCart = false,
  orderNumber = null,
  onAction = noOp,
  actionTitle = '',
} = {}) => {
  const itemizedList = (
    <>
      <Divider />
      {groupedCartItems.map(orderItemTemplate)}
    </>
  );

  return (
    <div className={styles.orderSummaryWrapper}>
      {!isConfirmation && !isCart && (
        <>
          <h2>In Your Cart</h2>
          <a id="edit-order-link" className="edit-link" href onClick={onEditOrder}>
            Edit
          </a>
        </>
      )}
      {isConfirmation && <h2 style={{ textAlign: 'center' }}>Order# {orderNumber}</h2>}
      {isCart && <h2>Total</h2>}
      <Divider />

      {orderBreakdownTemplate({
        orderSubtotal,
        taxesIncluded,
        orderDiscountsTotal,
        orderTaxTotal,
        orderGrandTotal,
      })}

      {!isConfirmation && (
        <Row>
          <Col span={24}>
            <Button
              block
              className="place-order-button"
              type="primary"
              onClick={onAction}
            >
              {actionTitle}
            </Button>
          </Col>
        </Row>
      )}

      {!isCart && itemizedList}
    </div>
  );
};

const HideWithNoItems = props =>
  props.orderItems.length === 0 ? null : <EmbeddedOrderSummary {...props} />;

export default HideWithNoItems;
