import config from 'config';
import { css } from 'emotion';

export const orderProgress = css`
  width: 300px;
  margin: 0 auto 75px auto;
  .step {
    width: 30%;
    height: 8px;
    border-radius: 4px;
    margin: 0 4px;
    background-color: ${config.retail ? '#000' : 'var(--purp-200)'};
    display: inline-block;
    opacity: 0.2;
  }
  .step.completed {
    opacity: 1;
  }
  .step.current {
    opacity: 1;
  }
`;
