import { css } from 'emotion';

export const productCard = css`
  position: relative;
  background: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  padding: 20px;
  margin-top: 18px;
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: auto 25%;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'image-name-container image-name-container'
    'strain-name-container strain-name-container'
    'quantity-select single-item-price'
    'total-cost delete-item';

  .image-name-container {
    grid-area: image-name-container;
    text-align: center;
    align-items: center;
    img {
      width: 100%;
      aspect-ratio: 1/1;
      display: inline-block;
      object-fit: cover;
      border: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }

  .strain-name-container {
    grid-area: strain-name-container;
    color: var(--gray-900);
  }

  .price-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-700);
    line-height: 22px;
    display: flex;
  }

  .single-item-price {
    grid-area: single-item-price;
    justify-content: end;
    white-space: nowrap;
  }

  .quantity-select {
    grid-area: quantity-select;
    width: 100%;
  }

  .total-cost {
    grid-area: total-cost;
  }

  .delete-item {
    grid-area: delete-item;
    text-align: right;
  }

  @media (min-width: 440px) {
    column-gap: 24px;
    row-gap: 24px;
    grid-template-rows: auto auto;
    grid-template-columns: 112px 87px auto 40px 18px;
    grid-template-areas:
      'image-name-container strain-name-container strain-name-container strain-name-container strain-name-container'
      'image-name-container quantity-select single-item-price total-cost delete-item';

    .strain-name-container {
      margin-right: 0;
    }
    .single-item-price {
      justify-content: start;
    }
    .delete-item {
      text-align: left;
    }
  }
`;
