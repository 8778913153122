import { createViewModel as createEmbeddedOrderViews } from '../embeddedOrder/viewModel';
import { createTasks as createStoreTasks } from '../../tasks';
import { createViewModel as createStoreViewModel } from '../../viewModel';
import { createViewModel } from '../viewModel';
import finalizePreOrder from 'apiRequests/embedded-menu/preOrder/finalize';
import { getCurrentState } from 'utils/redux';
import { routes } from 'router';

const _dependencies = {
  createViewModel: (state = getCurrentState()) => createViewModel(state),
  createStoreTasks,
  routes,
};

export const createTasks = (dispatch, props = {}, dependencies = _dependencies) => {
  const { createViewModel: views, createStoreTasks, routes } = dependencies;
  const {
    completeOrder,
    display,
    resume,
    reloadProducts,
    removeItemFromPreOrder,
    updateItemQuantityForPreOrder,
  } = createStoreTasks(dispatch, props);

  const { cart, customerInfo } = routes;

  const startCheckout = async () => {
    await customerInfo(views().currentStoreDisplayArgs);
  };

  const placeOrderImpl = async () => {
    const { preOrderData } = createEmbeddedOrderViews(getCurrentState());

    const { clientId, menuId, retailPreOrderId } = createStoreViewModel(
      getCurrentState()
    );

    const {
      order: { orderNumber },
    } = await finalizePreOrder({ clientId, menuId, retailPreOrderId, preOrderData });

    return { orderNumber };
  };

  const displayCart = async () => {
    const { currentStoreDisplayArgs } = views();

    await cart(currentStoreDisplayArgs);
  };

  const viewCart = async () => {
    await displayCart();
  };

  const backToStore = async () => {
    const { currentStoreDisplayArgs } = views();
    await reloadProducts();
    await display(currentStoreDisplayArgs);
  };

  const init = async () => {
    await resume();

    const { isEmpty } = views();

    const action = isEmpty ? backToStore : displayCart;

    await action();
  };

  const placeOrder = async () => {
    const { canPlaceOrder } = views();

    if (!canPlaceOrder) return;

    const { orderNumber } = await placeOrderImpl();

    await completeOrder(orderNumber);
  };

  return {
    init,
    placeOrder,
    backToStore,
    viewCart,
    startCheckout,
    removeItemFromPreOrder,
    updateItemQuantityForPreOrder
  };
};

export const __test__ = {
  dependencies: _dependencies,
};
