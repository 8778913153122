let orderSummary = {};

export const changeOrderSummaryTo = val => (orderSummary = val);
export const clearOrderSummary = () => {
  orderSummary = {};
};

export default () => ({
  ...orderSummary,
  isConfirmation: true,
});
