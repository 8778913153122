import { Fragment } from 'react';
import FullScreenSpinner from 'components/FullScreenSpinner';
import RequestStatus from 'components/RequestStatus';
export default ({ addProductToCartStatus, updateItemQuantityForPreOrderStatus, children }) => {
  const status = addProductToCartStatus || updateItemQuantityForPreOrderStatus;
  const nonBusyTemplate = children;

  const busyTemplate = (
    <Fragment>
      <FullScreenSpinner />
      {nonBusyTemplate}
    </Fragment>
  );

  return (
    <div id="mainBody">
      <div className="inner-container">
        {/* TO DO This is where we set the template */}
        <RequestStatus
          status={status}
          completedTemplate={nonBusyTemplate}
          inactiveTemplate={nonBusyTemplate}
          failedTemplate={nonBusyTemplate}
          startedTemplate={busyTemplate}
        />
      </div>
    </div>
  );
};
