import ProductCard from '../ProductCard';
import React from 'react';
import { noOp } from 'utils/core/funcy';

const CartItemCard = ({
  item: {
    quantityInCart = 0,
    price = 0,
    imageUrl = '',
    orderItemIds = [],
    productId = 0,
    unitWeight = 0,
    unitWeightUOM = '',
  } = {},
  productWithFullDetails = noOp,
  updateItemQuantityForPreOrder = noOp,
} = {}) => {
  const changeCartQuantityProps = {
    updateItemQuantityForPreOrder,
    productId,
    quantityInCart
  };
  return (
  <div>
    <ProductCard
      {...productWithFullDetails(productId)}
      {...changeCartQuantityProps}
      price={price}
      imageUrl={imageUrl}
      quantityInCart={quantityInCart}
      orderItemIds={orderItemIds}
      unitWeight={unitWeight}
      unitWeightUOM={unitWeightUOM}
    />
  </div>
);
 };

export default CartItemCard;
