import './reducers';
import { hocWithMappings as hoc, page } from 'utils/views';
import Template from './template';
import controller from './controller';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';

const TemplateWithController = hoc('Embedded Order Info - ux', controller);

export default page({
  Template: TemplateWithController(Template),
  createTasks,
  createViewModel,
});
