import { css } from 'emotion';

export const PoweredByContainer = css`
  margin-top: 40px;
  margin-bottom: 30px;

  .powered-by-text {
    width: 50%;
    display: block;
    float: left;
    text-align: right;
    padding-right: 3px;
    font-size: 0.9em;
  }

  .logo-container {
    display: inline-block;
    width: 50%;
  }

  .logo {
    width: 75px;
    padding-left: 3px;
  }
`;
