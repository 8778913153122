import React from 'react';
import { orderProgress } from './styles';

export const OrderProgress = ({ steps = 3, currentStep = 1 }) => {
  const generateClassName = stepNumber => {
    let className = 'step';
    if (currentStep === stepNumber) {
      className += ' current';
    }
    if (currentStep > stepNumber) {
      className += ' completed';
    }
    return className;
  };

  const generateSteps = () => {
    const elements = [...Array(steps)].map((e, i) => (
      <div className={generateClassName(i + 1)} />
    ));
    return elements;
  };
  return <div className={orderProgress}>{generateSteps()}</div>;
};

export default OrderProgress;
