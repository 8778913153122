import axios from 'axios';
import config from 'config';
import storeListingMapper from './storeListingMapper';

const getUOMAbbreviation = UOM => {
  switch (UOM) {
    case 'Each':
      return 'each';
    case 'Ounces':
    case 'Oz':
      return 'oz';
    case 'Grams':
      return 'g';
    case 'Milligrams':
      return 'mg';
    default:
      return false;
  }
};

// Id: pkg && pkg.id,
// ProductName: product && product.name,
// ItemStatus: 'Active',
// Category: product && product.category,
// Vendor: product && product.supplier && product.supplier.name,
// Brand: product && product.brand,
// Type: product && product.category,
// Dominance: product && product.cannabisType,
// Strain: product && product.strain,
// Quantity: pkg && pkg.inventoryQty,
// Unit: getUOMAbbreviation(product && product.unitWeightUOM),
// CannaUnit: 'g',
// Sku: pkg && pkg.sku,
// Description: product && product.description,
// PictureUrl: product && product.image,
// ThumbnailUrl: null,
// Price:
//   (product && product.price) ||
//   (product && product.priceMedical) ||
//   (product && product.priceMember) ||
//   null,
// PriceTiers: [priceTier, medicalTier, memberTier, defaultTier],
// Weight: product && product.unitWeight,
// Cbc: product && product.testResults && product.testResults.cbc,
// Cbd: product && product.testResults && product.testResults.cbd,
// Cbda: product && product.testResults && product.testResults.cbda,
// Cbn: product && product.testResults && product.testResults.cbn,
// Thc: product && product.testResults && product.testResults.thc,
// Thca: product && product.testResults && product.testResults.thca,
// SourceSystem: 'Growflow',
// ProductId: product && product.id,

const mapData = data =>
  data.reduce((accum, menuGroup) => {
    const { products, name: parentCategory } = menuGroup;

    const mappedProducts = products.map(product => ({
      ...product,
      price:
        ((product && product.price) ||
          (product && product.priceMedical) ||
          (product && product.priceMember)) / 100,
      name: product && product.name,
      description: product && product.description,
      discount: 0,
      isSample: false,
      msrp:
        ((product && product.price) ||
          (product && product.priceMedical) ||
          (product && product.priceMember)) / 100,
      quantityType: product && product.uom === 'Grams' ? 'CountBased' : null,
      available: product.inventoryQty,
      bulkGramsAvailable: product.uom === 'Grams' ? product.inventoryQty : null,
      size: product && product.uom === 'Grams' ? product && product.unitWeight : null,
      imageUrl: product.image,
      thumbNailImageUrl: product.image,
      videoEmbedLink: null,
      unitName: product && product.uom,
      unitShortName: getUOMAbbreviation(product.uom),
      strainName: product && product.strain,
      strainTypeName: product && product.cannabisType,
      strainNotes: null,
      productAttributes: null,
      productCategoryId: product && product.categoryId,
      productCategoryName: product && product.category,
      productCategoryParentName: parentCategory,
      productCategoryParentId: parentCategory,
      productBrandId: product && product.brandId,
      productBrandName: product && product.brand,
      minTHCMax: product && product.testResults && product.testResults.thc,
      maxTHCMax: product && product.testResults && product.testResults.thc,
      minCBDMax: product && product.testResults && product.testResults.cbd,
      maxCBDMax: product && product.testResults && product.testResults.cbd,
      minTotalTerpenes: 0,
      maxTotalTerpenes:
        product && product.testResults && product.testResults.totalTerpenes,
      maxTotalOptional: product && product.testResults && product.testResults.thca,
      minTotalOptional: 0,
    }));

    return accum.concat(...mappedProducts);
  }, []);

const getProducts = ({ clientId = '', menuId = '' } = {}) =>
  axios.get(
    config.embedded.buildURI({ clientId, uri: `/api/menus/embedded/${menuId}` }),
    {
      headers: {
        'partner-key': config.embedded.partnerKey,
      },
    }
  );

export const request = async ({ clientId = '', menuId = '' } = {}) => {
  try {
    const {
      data: { response },
    } = await getProducts({ clientId, menuId });

    const { menu, ...storeDetails } = response;
    const productResult = mapData(menu);
    const result = storeListingMapper(productResult, storeDetails);

    return result;
  } catch ({ message, response }) {
    // eslint-disable-next-line no-console
    console.log(message);

    return message || response.data;
  }
};
