import { css } from 'emotion';

export const checkoutContainer = css`
  max-width: 1024px;
  padding: 10px;
  margin: 39px auto 0;


  fieldset {
    h3 {
      margin-bottom: 20px;
    }
  }

  .desktop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .store-info {
      flex: 1 1 100%;
      margin-bottom: 24px;
    }
  }

  .leftcolumn {
    background-color: white;
    padding: 20px;
  }

  .greeting {
    text-align: center;
  }

  .ant-select {
    .anticon svg {
      color: var(--gray-400);
    }
  }

  .fields-container {
    background: white;
    padding: 1px 20px 20px 20px;
    border: 1px solid var(--gray-400);
    margin-bottom: 20px;
    .ant-radio-inner,
    .ant-radio-inner::after {
      border-color: #000;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: #000;
    }
    .ant-radio-inner::after {
      background-color: #000;
    }
    .ant-input:focus,
    .ant-input:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper:hover,
    .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper:hover,
    .ant-select-open .ant-select-selection,
    .ant-select-selection:active,
    .ant-select-selection:hover {
      border-color: black;
      box-shadow: none;
    }
  }

  form {
    margin-top: 24px;
    > div + div {
      margin-top: 24px;
    }
    input[type='text'] {
      margin-top: 2px;
    }
  }

  .field-error-message {
    color: var(--red-300);
  }

  .ant-input.error {
    border-color: var(--red-300);
  }

  .back-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    margin-top: 24px;
    margin-bottom: 35px;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    .icon {
      position: relative;
      margin-right: 7px;
    }
    p {
      text-transform: uppercase;
      color: #000;
      margin-top: 0;
      .icon {
        position: relative;
        top: 1px;
      }
    }
  }

  .setup-alert {
    margin-top: 24px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    > button + button {
      margin-left: 24px;
    }
  }
  .remove-button {
    padding-right: 0;
  }

  .place-order-container {
    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:active {
      background-color: #000;
      border-color: #000;
    }

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    .total-container {
      text-align: right;
      p {
        margin-top: 0;
      }
    }
    button {
      margin-left: 35px;
    }
  }

  .bottom-place-order {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    .back-cart {
      margin-top: 24px;
    }

    .place-order-container {
      margin-top: 0;
    }

    .bottom-place-order {
      margin-top: 24px;
      padding-left: 50px;
    }
  }

  .cart-overview-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'cart-overview-products'
      'cart-overview-totals';

    grid-row-gap: 24px;

    @media (min-width: 768px) {
      grid-template-columns: 2fr 1fr;
      grid-template-areas: 'cart-overview-products cart-overview-totals';
      grid-template-rows: auto;
      grid-column-gap: 24px;
    }
  }

  .cart-overview-products {
    margin-top: -18px;
    grid-area: cart-overview-products;
  }

  .cart-overview-totals {
    grid-area: cart-overview-totals;
  }
`;
