import React, { Fragment } from 'react';

import AddToCart from '../../../AddToCart';
import OversoldInventoryWarning from '../../../OversoldInventoryWarning';
import { noOp } from 'utils/core/funcy';
import { preventDefaultAndStopPropagation } from 'utils/ui/createEventHandler';
import { toCurrency } from 'utils/ui/mappers';

const ListingCard = ({
  listing: { products = [] } = {},
  isMobile = false,
  changeCartQuantity,
  productWithFullDetails = noOp,
  textQuantityForProduct = noOp,
} = {}) => {
  const productTemplate = ({ id = '' } = {}) => {
    const {
      availabilityDescription,
      sizeDisplayName,
      price,
      total,
      cartQuantity,
      isRemainingInventoryOversold,
      maxAmountInCart,
      isCountBased,
    } = productWithFullDetails(id);

    const addToCartProps = {
      id,
      changeCartQuantity,
      maxAmountInCart,
      cartQuantity,
      total,
      textQuantityForProduct,
      isCountBased,
    };

    return (
      <Fragment key={id}>
        <p className="regular product-row">{sizeDisplayName}</p>
        <p className="regular product-row">{price ? toCurrency(price) : '$0.00'}</p>
        {!isMobile ? (
          <p className="regular product-row">{availabilityDescription}</p>
        ) : null}
        <AddToCart {...addToCartProps} />

        <div className="grid-row-spacer" />
        <OversoldInventoryWarning
          isRemainingInventoryOversold={isRemainingInventoryOversold}
          isMobile={isMobile}
          maxAmountInCart={maxAmountInCart}
        />
      </Fragment>
    );
  };

  return (
    <>
      {!isMobile ? (
        <div
          className="add-to-cart-container"
          onClick={preventDefaultAndStopPropagation(noOp)}
        >
          <h5 className="header">Size</h5>
          <h5 className="header">Price</h5>
          {!isMobile ? <h5 className="header">Available</h5> : null}
          <h5 className="header">Qty</h5>
          <h5 className="header" />
          <div className="grid-header-spacer"></div>
          {products.map(productTemplate)}
        </div>
      ) : null}
    </>
  );
};

export default ListingCard;
