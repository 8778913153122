import Image from 'components/Image';
import ListingDetails from '../../../ListingDetails';
import React from 'react';
import { Tag } from 'antd';
import config from 'config';
import { listingCard } from '../../styles';
import { noOp } from 'utils/core/funcy';

const ListingCard = (props = {}) => {
  const {
    listing: {
      id: listingCardId,
      imageUrl = '',
      products = [],
      sizes = [],
      // eslint-disable-next-line no-unused-vars
      priceRange: { start: lowestPrice = 0, end: highestPrice = 0 } = {},
      thcRange: {
        available: thcAvailable = false,
        description: thcDescription = '',
      } = {},
      cbdRange: {
        available: cbdAvailable = false,
        description: cbdDescription = '',
      } = {},
      // totalOptionalRange: {
      //   available: totalOptionalAvailable = false,
      //   description: totalOptionalDescription = '',
      // } = {},
      // terpenesRange: {
      //   available: terpenesAvailable = false,
      //   description: terpenesDescription = '',
      // } = {},
    } = {},
    isMobile = false,
    productWithFullDetails = noOp,
    createListingCardActions = {},
  } = props;
  const firstProduct = products.length > 0 ? productWithFullDetails(products[0].id) : {};

  const { name = '', strainTypeName = '', strain = '', brand = '', available = 0 } = firstProduct;

  if (available === 0) return null;
  return (
    <div
      className={listingCard}
      key={listingCardId}
      {...createListingCardActions(listingCardId)}
    >
      <div className="image-name-container">
        <Image src={imageUrl || config.images.fallback} alt={`image of ${name}`} />
      </div>
      <div className="strain-name-container">
        {brand && <p className="brand-label">{brand}</p>}
        <h4 className="semi-bold">
          {strain} {name}
        </h4>
      </div>
      <div className="strain-type-name-container">
        {strainTypeName && <Tag className="label">{strainTypeName}</Tag>}
      </div>
      <div className="terpenes-container">
        <p className="terpene-label">
          {thcAvailable ? `THC: ${thcDescription}` : null}
          {thcAvailable && cbdAvailable ? ' | ' : null}
          {cbdAvailable ? `CBD: ${cbdDescription}` : null}
        </p>
      </div>
      <ListingDetails isMobile={isMobile} sizes={sizes} {...props} />
    </div>
  );
};

export default ListingCard;
