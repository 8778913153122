import Avo from './avo';
import config from 'config';
import { createTrackingFunction } from './utils';
import { productMapper } from './mappers';
export * from './events';

const mapStoreFrontDetails = ({ id: storeId, name: storeName }) => ({
  storeId,
  storeName,
});

export const initialize = () => {
  if (!config.analytics.enabled) return;
  Avo.initAvo({ env: config.analytics.env });
};

export const productsSearched = createTrackingFunction((query, storeFront) => {
  Avo.productsSearched({ query, ...mapStoreFrontDetails(storeFront) });
});

export const productClicked = createTrackingFunction((product, storeFront) => {
  Avo.productClicked({ ...productMapper(product), ...mapStoreFrontDetails(storeFront) });
});

export const productAdded = createTrackingFunction((product, storeFront) => {
  Avo.productAdded({
    cartId: '',
    ...productMapper(product),
    ...mapStoreFrontDetails(storeFront),
  });
});

export const productRemoved = createTrackingFunction((product, storeFront) => {
  Avo.productRemoved({
    cartId: '',
    ...productMapper(product),
    ...mapStoreFrontDetails(storeFront),
  });
});

export const cartViewed = createTrackingFunction((cart, storeFront) => {
  Avo.cartViewed({
    cartId: cart.cartId,
    products: cart.products.map(productMapper),
    ...mapStoreFrontDetails(storeFront),
  });
});

export const productListFiltered = createTrackingFunction((filters, storeFront) => {
  Avo.productListFiltered({
    listId: '', // Not sure what this should be yet.,
    category: '', // Not sure if we need to use this.,
    filters: filters.map(f => {
      var avoFilter = {
        type: f.name,
        value: f.value,
      };

      return avoFilter;
    }),
    ...mapStoreFrontDetails(storeFront),
  });
});

export const orderCreated = createTrackingFunction((order, storeFront) => {
  Avo.orderCreated({
    orderId: order.id,
    affiliation: 'GF Storefront 2.0',
    total: order.total,
    revenue: order.total,
    shipping: 0, // No data for this
    tax: 0, // No data for this yet
    discount: 0, // No data for this yet
    coupon: '', // No data for this yet
    currency: 'usd',
    products: order.products.map(productMapper),
    ...mapStoreFrontDetails(storeFront),
  });
});

export const __test__ = {
  createTrackingFunction,
};
