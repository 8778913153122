import Axios from 'axios';
import config from 'config';

export const request = async ({ order, clientId, menuId }) => {
  const { data } = await Axios.post(
    config.embedded.buildURI({ clientId, uri: '/api/orders/create-preorder' }),
    order,
    {
      headers: {
        'menu-key': menuId,
        'partner-key': config.embedded.partnerKey,
      },
    }
  );

  return data;
};
