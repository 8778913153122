import * as styles from './styles';
import { Alert, Col, DatePicker, Divider, Input, Radio, Row, Select } from 'antd';
import React, { Fragment } from 'react';
import {
  columnFieldTemplate,
  createTemplateBuilder,
  inputTemplate,
} from './template.templates';
import { Field } from 'formik';
import FullScreenSpinner from 'components/FullScreenSpinner';
import { LeftOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';
import OrderProgress from 'components/OrderProgress';
import { OrderSummaryPlaceOrder as OrderSummary } from '../embeddedOrderSummary';
import PoweredByGrowFlow from 'components/PoweredByGrowFlow';
import RequestStatus from 'components/RequestStatus';
import { form } from 'utils/forms';
import { noOp } from 'utils/core/funcy';

const { TextArea } = Input;

const EmbeddedOrder = ({
  formSchema = {},
  form: formValues = {},
  onFormInitialized = noOp,
  onFormSubmit = noOp,
  backToCart = noOp,
  createDateChangeHandler = noOp,
  onSave = noOp,
  isMedicalStore = true,
  isMedRecStore = true,
  isDeliverySelected = false,
  requiresMedicalInfo = false,
  orderTypes = [],
  defaultOrderType = '',
  states = [],
  createOrderStatus = {},
  errors: { hasErrorToDisplay = false, message: errorMessage = '' } = {},
  inputRefs = {},
  createMedicalPatientStatusChangedHandler = noOp,
  createStateChangeHandler = noOp,
} = {}) => {
  const orderTypeOption = orderType => (
    <Field type="radio" name="preOrderType" key={orderType.id}>
      {({ field }) => (
        <Radio
          onClick={orderType.onClick}
          id={orderType.id}
          disabled={orderType.isDisabled}
          {...field}
          value={orderType.value}
        >
          {orderType.description}
        </Radio>
      )}
    </Field>
  );

  const orderTypeTemplate = templateBuilder =>
    templateBuilder(() => (
      <fieldset>
        <h3>1. Order Type</h3>
        <Radio.Group
          defaultValue={defaultOrderType}
          role="group"
          aria-labelledby="preOrderType"
        >
          {orderTypes.map(orderTypeOption)}
        </Radio.Group>
        <Divider />
      </fieldset>
    ));

  const customerFirstNameTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'First Name',
        fieldName: 'firstName',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'firstName',
          placeholder: 'First Name',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.firstName,
          },
        })
    );

  const customerLastNameTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Last Name',
        fieldName: 'lastName',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'lastName',
          placeholder: 'Last Name',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.lastName,
          },
        })
    );

  const customerPhoneNumberTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Phone Number',
        fieldName: 'phoneNumber',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'phoneNumber',
          InputType: MaskedInput,
          inputAttrs: {
            mask: '(111) 111-1111',
            type: 'text',
            ref: inputRefs.phoneNumber,
          },
          templateBuilder,
        })
    );

  const customerEmailTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Email Address',
        fieldName: 'email',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'email',
          placeholder: 'Email',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.email,
          },
        })
    );

  const customerDOBTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Date of Birth',
        fieldName: 'dob',
        templateBuilder,
      },
      ({ setFieldValue, field }) =>
        inputTemplate({
          field,
          fieldName: 'dob',
          placeholder: 'yyyy-mm-dd',
          defaultValue: null,
          templateBuilder,
          inputAttrs: {
            onChange: createDateChangeHandler({ setFieldValue, fieldName: 'dob' }),
            ref: inputRefs.dob,
          },
          InputType: DatePicker,
        })
    );

  const medicalLicenseNumberTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Medical License Number',
        fieldName: 'medicalLicenseNumber',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'medicalLicenseNumber',
          placeholder: 'Medical License Number',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.medicalLicenseNumber,
          },
        })
    );

  const medicalLicenseExpirationTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Medical License Expiration',
        fieldName: 'medicalLicenseExpires',
        templateBuilder,
      },
      ({ setFieldValue, field }) =>
        inputTemplate({
          field,
          fieldName: 'medicalLicenseExpires',
          InputType: DatePicker,
          placeholder: 'yyyy-mm-dd',
          inputAttrs: {
            onChange: createDateChangeHandler({
              setFieldValue,
              fieldName: 'medicalLicenseExpires',
            }),
            ref: inputRefs.medicalLicenseExpires,
          },
          templateBuilder,
        })
    );

  const medicalPurchaseTemplate = templateBuilder =>
    requiresMedicalInfo
      ? templateBuilder(() => (
          <>
            <Row gutter={[12, 32]}>{medicalLicenseNumberTemplate(templateBuilder)}</Row>
            <Row gutter={[12, 32]}>
              {medicalLicenseExpirationTemplate(templateBuilder)}
            </Row>
          </>
        ))
      : null;

  const medicalPatientTemplate = templateBuilder =>
    isMedRecStore
      ? templateBuilder(({ handleChange }) => (
          <Row gutter={[12, 32]}>
            {columnFieldTemplate(
              {
                fieldName: 'isMedicalPatient',
                labelTitle: 'Are you a medical patient?',
                labelTemplate: val => <strong>{val}</strong>,
                templateBuilder,
              },
              ({ field }) => (
                <Radio.Group
                  onChange={createMedicalPatientStatusChangedHandler(handleChange)}
                >
                  <Radio
                    {...field}
                    ref={inputRefs.isMedicalPatient}
                    id="isMedicalPatient"
                    value={false}
                  >
                    No
                  </Radio>
                  <Radio {...field} id="isMedicalPatient" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              )
            )}
          </Row>
        ))
      : null;

  const customerMedicalPurchaseTemplate = templateBuilder =>
    isMedicalStore ? (
      <>
        {medicalPatientTemplate(templateBuilder)}
        {medicalPurchaseTemplate(templateBuilder)}
      </>
    ) : null;

  const customerInfoTemplate = templateBuilder =>
    templateBuilder(() => (
      <fieldset>
        <h3>2. Customer Info</h3>
        <Row gutter={[12, 32]}>
          {customerFirstNameTemplate(templateBuilder)}
          {customerLastNameTemplate(templateBuilder)}
        </Row>
        <Row gutter={[12, 32]}>{customerPhoneNumberTemplate(templateBuilder)}</Row>
        <Row gutter={[12, 32]}>{customerEmailTemplate(templateBuilder)}</Row>
        <Row gutter={[12, 32]}>{customerDOBTemplate(templateBuilder)}</Row>
        {customerMedicalPurchaseTemplate(templateBuilder)}
      </fieldset>
    ));

  const streetAddress1Template = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Street Address 1',
        fieldName: 'streetAddress1',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'streetAddress1',
          placeholder: 'Street Address',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.streetAddress1,
          },
        })
    );

  const streetAddress2Template = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Street Address 2',
        fieldName: 'streetAddress2',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'streetAddress2',
          placeholder: 'Street Address 2',
          templateBuilder,
        })
    );

  const cityTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'City',
        fieldName: 'city',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'city',
          placeholder: 'City',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.city,
          },
        })
    );

  const stateTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'State',
        fieldName: 'state',
        templateBuilder,
        colAttrs: { lg: { span: 6 } },
      },
      ({ field, values, setFieldValue }) => (
        <div>
          <Select
            {...field}
            id="state"
            name="state"
            fieldName="state"
            className="state-selector"
            options={states}
            style={{ width: '100%' }}
            defaultValue={values.state}
            value={values.state}
            onChange={createStateChangeHandler(setFieldValue)}
            placeholder="Select A State..."
            ref={inputRefs.state}
          >
            {states.map(state => (
              <Select.Option key={state.id} value={state.value}>
                {state.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      )
    );

  const zipCodeTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        labelTitle: 'Zipcode',
        fieldName: 'zipcode',
        templateBuilder,
        colAttrs: { lg: { span: 6 } },
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'zipcode',
          templateBuilder,
          inputAttrs: {
            ref: inputRefs.zipcode,
          },
        })
    );

  const deliveryOptionsTemplate = templateBuilder =>
    isDeliverySelected
      ? templateBuilder(() => (
          <>
            <Divider />
            <fieldset>
              <h3>3. Delivery Address</h3>
              <Row gutter={[12, 32]}>
                {streetAddress1Template(templateBuilder)}
                {streetAddress2Template(templateBuilder)}
              </Row>
              <Row gutter={[12, 32]}>
                {cityTemplate(templateBuilder)}
                {stateTemplate(templateBuilder)}
                {zipCodeTemplate(templateBuilder)}
              </Row>
            </fieldset>
          </>
        ))
      : null;

  const notesTemplate = templateBuilder =>
    columnFieldTemplate(
      {
        fieldName: 'specialInstructions',
        templateBuilder,
      },
      ({ field }) =>
        inputTemplate({
          field,
          fieldName: 'specialInstructions',
          placeholder: 'Include notes or special instructions for the order',
          templateBuilder,
          InputType: TextArea,
          inputAttrs: {
            rows: 4,
          },
        })
    );

  const specialNotesTemplate = templateBuilder =>
    templateBuilder(() => (
      <>
        <Divider />
        <fieldset>
          <h3>3. Special Instructions</h3>
          <Row gutter={[12, 32]}>{notesTemplate(templateBuilder)}</Row>
        </fieldset>
      </>
    ));

  const fieldsTemplate = ({ setFieldValue, values, handleChange, errors, touched }) => {
    const builder = createTemplateBuilder({
      setFieldValue,
      values,
      errors,
      handleChange,
      touched,
    });

    return (
      <>
        {orderTypeTemplate(builder)}
        {customerInfoTemplate(builder)}
        {deliveryOptionsTemplate(builder)}
        {specialNotesTemplate(builder)}
      </>
    );
  };

  const errorTemplate = hasErrorToDisplay ? (
    <div className="place-order-error" style={{ marginBottom: '20px' }}>
      <Alert
        message="An error occurred while placing your order."
        description={errorMessage}
        type="error"
        showIcon
      />
    </div>
  ) : null;

  const normalTemplate = (
    <Fragment>
      <OrderProgress steps={3} currentStep={2} />
      <div className="greeting">
        <h2>Checkout</h2>
      </div>
      <a href="#" className="back-cart" onClick={backToCart}>
        <LeftOutlined className="icon" />
        Back to cart
      </a>
      {errorTemplate}
      <Row gutter={[48, 0]}>
        <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}>
          <div className="fields-container">
            {form({
              implementationAttributes: { validationSchema: formSchema },
              renderFields: fieldsTemplate,
              onSubmit: onFormSubmit,
              initialValues: formValues,
              showActions: false,
              formInitHandler: onFormInitialized,
            })}
          </div>
        </Col>
        <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}>
          <OrderSummary onAction={onSave} />
        </Col>
      </Row>
    </Fragment>
  );

  const busyTemplate = (
    <Fragment>
      <FullScreenSpinner />
      {normalTemplate}
    </Fragment>
  );

  const layoutTemplate = (
    <RequestStatus
      status={createOrderStatus}
      inactiveTemplate={normalTemplate}
      startedTemplate={busyTemplate}
      completedTemplate={normalTemplate}
      failedTemplate={normalTemplate}
    />
  );

  return (
    <div>
      <div className={styles.checkoutContainer}>{layoutTemplate}</div>
      <Divider />
      <PoweredByGrowFlow />
    </div>
  );
};

export default EmbeddedOrder;
