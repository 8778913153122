import Template from './impl';
import controllerLaunchDarkly from 'utils/controllers/launchDarkly';
import { hocWithMappings as hoc } from 'utils/views';

const TemplateWithLaunchDarkly = hoc(
  'Cart Summary - with launch darkly flags',
  controllerLaunchDarkly
);

export default TemplateWithLaunchDarkly(Template);
