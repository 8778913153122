import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { createOrderStatusKey, eventNames } from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';
import { orderTypes } from './viewModel';

const sliceReducer = createSliceReducer(x => x.embeddedOrder);

export const newState = () => ({
  preOrderType: orderTypes.pickup.id,
  isMedicalPatient: null,
  specialInstructions: '',
  deliveryAddress: {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipcode: '',
  },
  customer: {
    type: '',
    firstName: '',
    lastName: '',
    id: null,
    dob: null,
    email: '',
    phone: '',
    isVeteran: false,
    isResident: false,
    isStudent: false,
    isSenior: false,
    isDisabled: false,
    isTaxExempt: false,
    driversLicenseNumber: null,
    driversLicenseExpires: null,
    medicalLicenseNumber: null,
    medicalLicenseDateIssued: null,
    medicalLicenseExpires: null,
  },
  createOrderStatus: requestState(),
});

const initialState = {
  embeddedOrder: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

registerRequestStatusChangeReducers(
  createOrderStatusKey,
  val => val.embeddedOrder.createOrderStatus
);
