export const addToCartContainerDisplayStyle = 'grid';

export const productCardImageOverrides = `
  cursor: pointer;
`;

export const gridTemplateAreasDesktop = `
  'image-name-container strain-name-container terpenes-container'
  'image-name-container add-to-cart-container add-to-cart-container'
`;

export const gridTemplateAreasMobile = `
  'image-name-container strain-name-container'
  'image-name-container size-cost-container'
`;

export const terpenesContainerTextAlign = 'left';
export const terpenesContainerTextAlignMobile = 'left';
