import { component, hocWithMappings as hoc } from 'utils/views';
import { Template } from './template';
import { createTasks } from './tasks';
import listingController from '../../listing/controller';

const TemplateWithController = hoc('Listing Card - Listing UX', listingController);

export default component({
  Template: TemplateWithController(Template),
  createTasks,
});
