import { createPreOrderUri, createRequestHeaders } from '../requestUtils';
import Axios from 'axios';

const createUri = ({ clientId = '', retailPreOrderId = -1 } = {}) =>
  createPreOrderUri({ clientId, retailPreOrderId, uri: '/finalize' });

export default async ({
  clientId = '',
  menuId = '',
  retailPreOrderId = -1,
  preOrderData,
} = {}) => {
  const r = await Axios.put(createUri({ clientId, retailPreOrderId }), preOrderData, {
    headers: createRequestHeaders({ menuId }),
  });

  return r.data;
};
