import config from 'config';
import productListingGroupKeyMapper from './productListingGroupKeyMapper';
import { v4 as uuid } from 'uuid';

const _storeFrontListingMapper = (
  products = [],
  reduceToListings,
  extraDetails = {}
) => ({
  listings: reduceToListings(products),
  products,
  ...extraDetails,
});

const _reduceToListings = (listingReducer, productListingMapper) => products => {
  const listingsMap = products.reduce(listingReducer, {});

  const listings = Object.values(listingsMap).map(productListingMapper);

  return listings;
};

const _listingReducer = (map, product) => {
  const key = productListingGroupKeyMapper(product);

  const list = map[key] || (map[key] = []);

  list.push(product);

  return map;
};

const _productListingMapper = (items = []) => ({
  id: uuid(),
  products: items.slice(0),
  imageUrl: items[0].imageUrl || config.images.fallback,
});

export default (products = [], extraDetails = {}) =>
  _storeFrontListingMapper(
    products,
    _reduceToListings(_listingReducer, _productListingMapper),
    extraDetails
  );

export const __test__ = {
  _listingReducer,
  _productListingMapper,
  _reduceToListings,
  _storeFrontListingMapper,
};
