import { Col, Input } from 'antd';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { identity } from 'utils/core/funcy';

export const createTemplateBuilder = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
}) => template => template({ setFieldValue, values, touched, errors, handleChange });

export const columnFieldTemplate = (
  {
    colAttrs: { lg = { span: 12 }, xs = { span: 24 } } = {},
    fieldName,
    labelTitle,
    templateBuilder,
    labelTemplate = identity,
  } = {},
  template
) => (
  <Col lg={lg} xs={xs}>
    <label className="regular" htmlFor={fieldName}>
      {labelTemplate(labelTitle)}
    </label>
    {templateBuilder(({ setFieldValue, handleChange, values, touched, errors }) => (
      <div>
        <Field name={fieldName} error={touched[fieldName] && errors[fieldName]}>
          {({ field }) => (
            <>
              {template({ setFieldValue, handleChange, values, touched, errors, field })}
              {errors[fieldName] && touched[fieldName] && (
                <div className="field-error-message">
                  <ErrorMessage name={fieldName} />
                </div>
              )}
            </>
          )}
        </Field>
      </div>
    ))}
  </Col>
);

export const inputTemplate = ({
  templateBuilder,
  field,
  fieldName,
  placeholder = '',
  inputAttrs = {},
  InputType = Input,
} = {}) =>
  templateBuilder(({ values, touched, errors, handleChange }) => (
    <InputType
      {...field}
      className={touched[fieldName] && errors[fieldName] && 'error'}
      id={fieldName}
      placeholder={placeholder}
      defaultValue={values[fieldName]}
      onChange={handleChange}
      {...inputAttrs}
    />
  ));
