import {
  hocWithMappings as hoc,
  pageWithAuth,
  withBreakpointDetection,
  withSEO,
} from 'utils/views';
import Template from './template';
import { compose } from 'utils/core/funcy';
import controller from './controller';
import controllerAnalytics from './controller.analytics';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Main as withLayout } from 'layouts';

const TemplateWithAnalyticsController = hoc(
  'Listing - With Analytics Tracking',
  controllerAnalytics
);

const TemplateWithController = hoc('Listing - With Event Handlers', controller);

const FullTemplate = compose(
  withBreakpointDetection(),
  TemplateWithAnalyticsController,
  TemplateWithController
)(Template);

export default withSEO({
  title: 'Listing',
})(
  pageWithAuth({
    Template: FullTemplate,
    withLayout,
    createTasks,
    createViewModel,
  })
);
