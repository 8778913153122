const storeUri = ({ clientId, menuId }) => `/retailMenu/${clientId}/${menuId}`;

const cartUri = ({ clientId, menuId }) => `${storeUri({ clientId, menuId })}/cart`;

const customerInfoUri = ({ clientId, menuId }) =>
  `${cartUri({ clientId, menuId })}/customerInfo`;

const orderCompleteUri = ({ clientId, menuId, orderNumber }) =>
  `${storeUri({ clientId, menuId })}/orderComplete/${orderNumber}`;

const store = route => async ({ clientId, menuId }) =>
  await route({ path: storeUri({ clientId, menuId }) });

const cart = route => async ({ clientId, menuId }) =>
  await route({ path: cartUri({ clientId, menuId }) });

const customerInfo = route => async ({ clientId, menuId }) =>
  await route({ path: customerInfoUri({ clientId, menuId }) });

const orderComplete = route => async ({ clientId, menuId, orderNumber }) =>
  await route({ path: orderCompleteUri({ clientId, menuId, orderNumber }) });

export const buildRoutes = route => ({
  cart: cart(route),
  customerInfo: customerInfo(route),
  orderComplete: orderComplete(route),
  store: store(route),
});

export const uris = {
  storeUri,
  cartUri,
  customerInfoUri,
  orderCompleteUri,
};
