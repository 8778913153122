import * as embedded from './embedded';
import * as live from './live';
import config from 'config';

const target = config.retail ? embedded : live;

export const {
  createStoreListingArgs,
  createView,
  createViewArgs,
  initializeStoreDetails,
  display,
  createTasks,
} = target;
